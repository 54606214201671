<template>
    <v-progress-linear v-if="!this.$route.params.fromDisplay" :value="progress.percentage" height="32" class="text-white"
        style="background-color:rgba(104, 184, 183, 1);">
        <span class="progress-text">
            <span>Temps restant estimé&nbsp;:&nbsp;</span>
            <span class="font-weight-bold">{{ progress.timing }}
                minutes</span>
        </span>
    </v-progress-linear>
</template>
<script>

export default {
    props: [
        'progress'
    ],
    components: {

    },
    data() {
        return {

        }
    },
    watch: {

    },
    created() {

    },
    methods: {

    }
}
</script>