import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#68b8b7',
        secondary: '#777',
        accent: '#fb8c00',
        success: '#4caf50',
        error: '#ff5252'
      }
    }
  }
}

export default new Vuetify(opts)
