<template>
    <div class="row justify-content-center" v-if="(data.state < 2)">
        <div class="col-6">
        <router-link class="font-weight-bold rounded-50 btn btn-block btn-primary text-white" :to="{ name: link, params:{fromDisplay:true} }" >{{text}}</router-link>
        
        </div>
    </div>

</template>
<script>



export default {
    props: [
        "progress",
        "data",
        "tribunal",
        "link",
        "text"
    ],
    components: {

    },
    data() {
        return {

        }
    },
    watch: {

    },
    created() {

    },
    methods: {



    }
}
</script>
