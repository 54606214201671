<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :rules="rules"
        :value="date"
        :label="translate('form-step-one-label6')"
        v-bind="attrs"
        hide-details="auto"
        outlined
        rounded
        class="secondary-input-bg date-input"
        @input="dateInput()"
        @blur="dateBlured($event)"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      color="primary"
      no-title
      locale="fr"
      :max="currentDate"
      @change="dateChanged"
      @input="menu = false"
    />
  </v-menu>
</template>
<script>
export default {
  model: {
    prop: 'date',
    event: 'input'
  },
  props: [
    'date',
    'rules'
  ],
  data () {
    return {
      menu: false,
      selectedDate: new Date().toISOString().substr(0, 10),
      currentDate: new Date().toISOString().substr(0, 10),
      withInput: false
    }
  },
  methods: {
    dateInput () {
      this.withInput = true
    },
    dateBlured (event) {
      this.selectedDate = this.parseDate(event.target.value, this.withInput)
      this.dateChanged()
    },
    dateChanged () {
      this.$emit('input', this.formatDate(this.selectedDate))
    },
    parseDate (date, format = false) {
      if (!date) {
        return null
      } else {
        let day, month, year
        const dateSplit = date.split('/')
        if (format) {
          day = dateSplit[0]
          month = dateSplit[1]
          year = dateSplit[2]
        } else {
          day = dateSplit[1]
          month = dateSplit[0]
          year = dateSplit[2]
        }
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
    }
  }
}
</script>
