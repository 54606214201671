<template>

          <v-form
            ref="firstStepForm"
            v-model="valid"
            autocomplete="off"
            @input="customValidation"
          >
            <div class="row">
              <div
                v-if="infraction"
                class="col-12"
              >
                <v-card
                  class="advice"
                  outlined
                >
                  <v-card-text>
                    <span>Vous avez choisi de déposer une plainte pour : <span class="font-weight-bold">{{ infraction.parent.label }} - {{ infraction.label }}</span></span><br>
                    <span>Si vous souhaitez changer de motif, <span
                      class="link text-primary font-weight-bold"
                      @click="showPrevious($event)"
                    >cliquez ici pour revenir à la page précédente</span></span><br>
                  </v-card-text>
                </v-card>
              </div>
              <!-- <div class="col-12"> -->
              <!-- <v-autocomplete
                                  v-model="data.infraction"
                                  :rules="data.rules.infraction"
                                  :items="Object.keys(infractionItems)"
                                  :label="translate('form-step-one-label1')"
                                  :hint="translate('form-step-one-hint1')"
                                  :persistent-hint="true"
                                  hide-details="auto"
                                  outlined
                                  rounded
                                  no-data-text='Infraction non trouvée, veuillez sélectionner "Autre infraction".'
                                  class="secondary-input-bg"
                                  clearable
                              ></v-autocomplete> -->
              <!-- <pre>{{ infraction }}</pre>
                          </div> -->
              <div class="col-12">
                <vuetify-google-autocomplete
                  id="map"
                  ref="address"
                  v-model="qualification.fullAddress"
                  classname="form-control"
                  :label="translate('form-step-one-fieldset-title')"
                  :rules="qualification.rules.fullAddress"
                  :hide-details="'auto'"
                  outlined
                  rounded
                  placeholder=""
                  class="secondary-input-bg"
                  country="fr"
                  browser-autocomplete="off"
                  @placechanged="getAddressData"
                />
              </div>
              <div class="col-12">
                <date-picker
                  v-model="qualification.date"
                  :rules="qualification.rules.date"
                />
              </div>
              <div class="col-12">
                <v-textarea
                  v-model="qualification.expose"
                  class="secondary-input-bg"
                  :rules="qualification.rules.expose"
                  :label="translate('form-step-one-label5')"
                  hide-details="auto"
                  :hint="translate('form-step-one-hint2')"
                  :persistent-hint="true"
                  rows="10"
                  outlined
                  rounded
                  aria-autocomplete="none"
                  autocomplete="off"
                />
              </div>
              <transition name="slide-fade">
                <div
                  v-if="infraction"
                  class="col-12"
                >
                  <v-card
                    class="advice"
                    outlined
                  >
                    <v-icon
                      class="right-icon"
                      medium
                    >
                      mdi-help-circle-outline
                    </v-icon>
                    <v-card-title>
                      {{ translate('form-step-one-advice-title') }}
                    </v-card-title>
                    <v-card-text>
                      <div class="tipsIntro">
                        {{ translate('form-step-one-advice-intro') }} <span style="color:#68b8b7">« {{ infraction.parent.label }} - {{ infraction.label }} »</span> :
                      </div>
                      <ol class="tipsList">
                        <li
                          v-for="(placeholder, index) in infraction.placeholder"
                          :key="'placeholder-sub-' + index"
                        >
                          {{ placeholder }}
                        </li>
                      </ol>
                    </v-card-text>
                  </v-card>
                </div>
              </transition>
              <div
                v-if="showErrors"
                class="col-12 text-danger text-center"
              >
                {{ 'error-general-validations' | translate }}
              </div>
              <div class="col-12">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-6">
                      <button
                        class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                        @click="showPrevious($event)"
                      >
                        Précédent
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase"
                        @click="validate($event)"
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 mx-auto">
                              <button class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase" @click="validate($event)">Suivant</button>
                          </div> -->
            </div>
          </v-form>

  </template>
  <script>
  import DatePicker from '../inputs/DatePicker'
  import Qualification from '../../../models/qualification'
  export default {
    components: {
      DatePicker
    },
    model: {
      prop: 'qualification',
      event: 'validated'
    },
    props: [
      'qualification',
      'infraction',

    ],
    data () {
      return {
        previousRoute:'infraction',
        nextRoute:'prejudice',
        valid: true,
        showErrors: false,
        data: new Qualification(),

        // infractionItems : [
        //     "Abandon de famille",
        //     "Abus de bien social",
        //     "Abus de confiance",
        //     "Abus frauduleux de l'ignorance ou de la faiblesse d'une personne vulnérable",
        //     "Agression sexuelle",
        //     "Appels téléphoniques malveillants",
        //     "Arrestation enlèvement séquestration",
        //     "Autre infraction pénale",
        //     "Bizutage",
        //     "Blessures involontaires",
        //     "Bruits anormaux du voisinage",
        //     "Chantage",
        //     "Conditions de travail ou d'hébergement contraires à la dignité",
        //     "Contrefaçon falsification de chèque et/ou usage",
        //     "Dégradations ou détérioration volontaire",
        //     "Dénonciation calomnieuse / Faits faux ou imaginaires",
        //     "Dépôt d'objet ou d'ordure dans un lieu non autorisé",
        //     "Diffamation",
        //     "Discrimination",
        //     "Empoisonnement / Administration de substances nuisibles",
        //     "Escroquerie",
        //     "Exhibition sexuelle",
        //     "Extorsion",
        //     "Faux / Usage de faux",
        //     "Faux témoignage ou attestation",
        //     "Fraude à la carte bancaire",
        //     "Harcèlement et chantage sexuel",
        //     "Harcèlement moral (cas général)",
        //     "Harcèlement moral dans le cadre du travail",
        //     "Harcèlement moral sur conjoint concubin partenaire pacsé",
        //     "Injure",
        //     "Menace de commettre un crime ou un délit",
        //     "Menace de mort reitérée",
        //     "Mise en danger delibérée d'autrui (risque de mort ou d'infirmité)",
        //     "Non représentation d'enfant à une personne ayant le droit de le réclamer",
        //     "Outrage sexiste",
        //     "Tapage injurieux ou nocture",
        //     "Usurpation d'identité",
        //     "Viol",
        //     "Viol aggravé",
        //     "Violation de domicile",
        //     "Violences aggravées",
        //     "Violences avec ITT de moins de huits jours",
        //     "Violences avec ITT de plus de huit jours",
        //     "Vol",
        //     "Vol aggravé (par une ou plusieurs circonstances)"
        // ],
        infractionItems: {

        }
      }
    },
    mounted () {
      this.returnLogoLinkToDefault()
      this.scrollToTop()
      window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'step0', value: 'Plainte en ligne : étape 0' })
      this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 1
        },)
    },
    beforeMount () {
    //   this.data = this.qualification
    },
    methods: {
      getAddressData: function (addressData, placeResultData, id) {
        this.qualification.address = addressData.name
        this.qualification.city = addressData.locality
        this.qualification.code = addressData.postal_code
      },
      validate (event) {
        event.preventDefault()

        this.$refs.firstStepForm.validate()
        if (this.valid) {
          window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'step1', value: 'Plainte en ligne : étape 1' })
        //   this.$emit('validated', this.qualification)
           // this.dossier.infraction = this.infractionData
            this.$emit('saveDossier', {
                'key' : 'qualification',
                'data' : this.qualification,
                'nextRoute': this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.nextRoute
            })

        } else {
          this.showErrors = true
        }
      },
      customValidation () {
        if (this.showErrors) {
          this.showErrors = !this.$refs.firstStepForm.validate()
        }
      },
      showPrevious (event) {
        event.preventDefault()
        // this.$emit('previous')
        // this.$router.push({ name: this.previousRoute })
        this.$emit('previous',{
            'previousRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.previousRoute
        })
      }
    }
  }
  </script>
  <style scoped>
      .link{
          cursor: pointer;
      }
      .advice {
          background-color : #F7F7F7 !important;
      }
      .advice .v-card__title {
          padding-bottom : 8px;
      }
      .tipsIntro {
          font-weight : bold;
      }
      /* Les animations d'entrée (« enter ») et de sortie (« leave »)  */
      /* peuvent utiliser différentes fonctions de durée et de temps.  */
      .slide-fade-enter-active {
          transition: all .3s ease;
      }
      .slide-fade-leave-active {
          transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
      }
      .slide-fade-enter, .slide-fade-leave-to
      /* .slide-fade-leave-active below version 2.1.8 */ {
          transform: translateX(10px);
          opacity: 0;
      }
      .right-icon {
          float: right;
          margin-top: 16px;
          margin-right: 16px;
      }
  </style>
