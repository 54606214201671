import './vendor/bootstrap'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './vendor/vuetify'
import * as env from './env'
import VuetifyGoogleAutocomplete from './components/form/inputs/autocomplete'

import Axeptio from './cookies-axeptio'
import $ from 'jquery'
$(window).off('beforeunload');

// const settings = {
//     clientId: env.AXEPTIO
// };

// const axeptio = new Axeptio( settings );

// axeptio.add( new GoogleTagManager( 'GTM-' +env.GTM) );

// // Load Axeptio script.
// axeptio.init();
const axeptio = new Axeptio();
axeptio.init()
window.Vue = Vue

import VueRouter from 'vue-router'

Vue.use(VueRouter)

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('__data_access_token') && localStorage.getItem('__data_access_token').trim() && (localStorage.getItem('__data_access_token') !== 'undefined')) {
      config.headers['access-token'] = `${localStorage.getItem('__data_access_token')}`;

    }
    return config;
  },
  // error => {
  //   if (error.response.status == 403) {
  //     console.log('get 403')
  //     // this.getAccessToken()
  //   }
  //   else {
  //     return Promise.reject(error);
  //   }
  // },
  response => {
    return response;
  },

);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: env.GOOGLE_API_KEY,
  language: 'fr'
})

Vue.mixin({
  filters: {
    translate(value) {
      const translations = require('../lang/fr.json')
      return translations[value] ? translations[value] : ''
    }
  },
  data() {
    return {
      translations: require('../lang/fr.json'),
      sessionId: localStorage.getItem('sessionId'),
      
    }
  },
  // watch:{
  //   dossier(value){
  //     console.log(value)
  //   }
  // },
  methods: {
    hideReturnWebsiteLink() {
      // const returnWebsiteLink = document.getElementById('return-website-link')
      // returnWebsiteLink.style.display = 'none'
    },
    showReturnWebsiteLink() {
      // const returnWebsiteLink = document.getElementById('return-website-link')
      // returnWebsiteLink.style.display = 'list-item'
    },
    returnLogoLinkToDefault() {
      // const defaultLogoLinkContainer = document.getElementById('default-logo-link-container')
      // const logoLinkContainer = document.getElementById('logo-link-container')
      // logoLinkContainer.style.display = 'none'
      // defaultLogoLinkContainer.style.display = 'block'
    },
    setLogoLinkOnClick(callback = null) {
      // const logoLink = document.getElementById('logo-link')
      // const defaultLogoLinkContainer = document.getElementById('default-logo-link-container')
      // const logoLinkContainer = document.getElementById('logo-link-container')
      // logoLinkContainer.style.display = 'block'
      // defaultLogoLinkContainer.style.display = 'none'
      // if (callback) {
      //   logoLink.style.cursor = 'pointer'
      //   logoLink.onclick = callback
      // } else {
      //   logoLink.style.cursor = 'default'
      //   logoLink.onclick = function (event) {
      //     event.preventDefault()
      //   }
      // }
    },
    scrollToTop() {
      setTimeout(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 300)
    },
    scrollToElement(selector) {
      setTimeout(function () {

        if (document.querySelector(selector)) {
          document.querySelector(selector).scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 300)
    },
    translate(value) {
      return this.translations[value] ? this.translations[value] : ''
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  },

})
// window.addEventListener('beforeunload', function (event) {
//   event.stopImmediatePropagation();
//   event.preventDefault();
//   event.returnValue = undefined;
//   return null;
// });
import router from './router'
import CustomFormData from './models/customFormData'



const app = new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

// const app = new Vue({
//   el: '#app',
//   vuetify,
//   router,
//   render: h => h(App)
// })
// .$mount('#app')

