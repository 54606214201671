import Qualification from './qualification'
import Prejudice from './prejudice'
import Coordonnees from './coordonnees'
import Tribunal from './tribunal'
import Infraction from './infraction'
import InfractionParent from './infractionParent'

export default class CustomFormData {
  constructor (
    initialized = false,
    id = 0,
    state = -1,
    reference ='',
    tracking_number ='',
    signature_url,
    are = '',
    cachet_poste = '',
    infraction = new Infraction(),
    qualification = new Qualification(),
    prejudice = new Prejudice(),
    coordonnees = new Coordonnees(),
    tribunal = new Tribunal()
  ) {
    this.id = id
    this.state = state
    this.reference = reference
    this.tracking_number = tracking_number
    this.are = are;
    this.cachet_poste = cachet_poste;
    this.signature_url=signature_url
    this.initialized = initialized
    this.infraction = infraction
    this.qualification = qualification
    this.prejudice = prejudice
    this.coordonnees = coordonnees
    this.tribunal = tribunal
  }

  reinitializeData () {
    this.id = 0
    this.state = -1
    this.reference = ''
    this.tracking_number = ''
    this.signature_url = ''
    this.are = '';
    this.cachet_poste = '';
    this.infraction = new Infraction()
    this.qualification = new Qualification()
    this.prejudice = new Prejudice()
    this.coordonnees = new Coordonnees()
    this.tribunal = new Tribunal()
    this.initialized = false
  }
  optimizeForStoring(){
    //unset(this.infraction.parent.children)
    let dataToStore = Object.assign({}, this);
    dataToStore.infraction.parent.children = []
    return dataToStore
  }

  initializeDataWithFormObject (form, tribunaux, infractions) {
    this.id = form.id
    this.state = form.state

    let infractionTemp = CustomFormData.searchInfractionById(form.id_infraction, infractions);
    if(!infractionTemp.id){
      infractionTemp = CustomFormData.searchInfractionByLabel(form.infraction, infractions);
    }
    this.infraction = infractionTemp
    
    this.reference = form.reference
    this.qualification.infraction = form.infraction
    this.qualification.fullAddress = form.qualification_full_adress
    this.qualification.address = form.qualification_adress
    this.qualification.code = form.qualification_code
    this.qualification.city = form.qualification_city
    this.qualification.date = form.date
    this.qualification.expose = form.expose
    this.prejudice.personnel = form.prejudice_personnel
    this.prejudice.preuves = form.prejudice_preuves
    this.coordonnees.civilite = form.civilite
    this.coordonnees.firstName = form.first_name
    this.coordonnees.lastName = form.last_name
    this.coordonnees.company = form.company
    this.coordonnees.fullAddress = form.coordonnees_full_adress
    this.coordonnees.address = form.coordonnees_adress
    this.coordonnees.code = form.coordonnees_code
    this.coordonnees.city = form.coordonnees_city
    this.coordonnees.phone = form.phone
    this.coordonnees.email = form.email
    this.coordonnees.majeur = form.consent_majeur
    this.coordonnees.juridique = form.consent_juridique
    this.tracking_number = form.tracking_number
    this.signature_url = form.signature_url
    this.are = form.are;
    this.cachet_poste = form.cachet_poste;
    this.tribunal = CustomFormData.searchTribunal(form.tribunal_code, tribunaux)
    this.initialized = true
  }
  static searchTribunal(code, tribunaux){
    for (let index = 0; index < tribunaux.length; index++) {
      const element = tribunaux[index];
      if(parseInt(element.code) === parseInt(code)){
        
        return element;
      }
      
    }
    return new Tribunal();
  }
  static searchInfractionById(id, infractions){
    
    for (let index = 0; index < infractions.length; index++) {
      const elementParent = infractions[index];
      for (let indexInfraction = 0; indexInfraction < elementParent.children.length; indexInfraction++) {
        const element = elementParent.children[indexInfraction];
        if(parseInt(element.id) === parseInt(id)){
          element.parent = new InfractionParent(elementParent.id, elementParent.label, elementParent.children, elementParent.special)
          return element;
        }
      }
      
      
    }
    return new Infraction();
  }
  static searchInfractionByLabel(label, infractions){
    
    for (let index = 0; index < infractions.length; index++) {
      const elementParent = infractions[index];
      for (let indexInfraction = 0; indexInfraction < elementParent.children.length; indexInfraction++) {
        const element = elementParent.children[indexInfraction];
        if((element.label) === (label)){
          element.parent = new InfractionParent(elementParent.id, elementParent.label, elementParent.children, elementParent.special)
          return element;
        }
      }
      
      
    }
    return new Infraction();
  }
}
