<template>
    <div>
    <ProgressLine :progress="progress"></ProgressLine>
    <HeaderTitle :progress="progress"></HeaderTitle>
    <Stepper v-if="(progress.actualStep > 0)"  :progress="progress" :steps="steps"></Stepper>
    <v-scroll-x-transition mode="out-in" appear>

        <div class="card mx-auto my-8 px-0 px-sm-3 card-container">
            <div class="card-body">

                <!-- <div>
                    <div class="card mx-auto my-8 px-0 px-sm-3">
                        <div class="card-body"> -->
                            <router-view :data="data" :formId="data.id" :formReference="data.reference"
                                :infraction="data.infraction" :qualification="data.qualification"
                                :prejudice="data.prejudice" :coordonnees="data.coordonnees" :tribunal="data.tribunal"
                                :infractionsItems="infractionsItems" :tribunauxItems="tribunauxItems"
                                @saveDossier="saveForm($event)" @previous="previousEvent($event)"
                                @updateProgress="updateProgress($event)"
                            >
                            </router-view>
                        <!-- </div>
                    </div>
                </div> -->
                
            </div>
        </div>
    </v-scroll-x-transition>
</div>
</template>
<script>
import { RouterView } from 'vue-router';
import CustomFormData from '../../models/customFormData';
import ProgressLine from './../progress/ProgressLine.vue'
import Stepper from './../progress/Stepper.vue'
import HeaderTitle from './../header/HeaderTitle.vue'

export default {
    props: [
        "data",
        "infraction",
        // "tribunaux",
        "tribunauxItems",
        "infractionsItems"
    ],
    components: {
        RouterView,
        ProgressLine,
        Stepper,
        HeaderTitle,
    },
    data() {
        return {
            // data: new CustomFormData(),
            // data,
            progress: {
                timing: '',
                percentage: 0,
                actualStep: 0
            },
            steps: [
                {
                    title: 'Infraction',
                    step:0,
                    fields: [
                        'parentInfraction',
                        'infraction'
                    ]
                },
                {
                    title: 'front-stepper-title-first-step-form',
                    step:1,
                    fields: [

                    ]
                },
                {
                    title: 'front-stepper-title-second-step-form',
                    step:2,
                },
                {
                    title: 'front-stepper-title-third-step-form',
                    step:3,
                },
                {
                    title: 'front-stepper-title-fourth-step-form',
                    step:4,
                },
                {
                    title: 'front-stepper-title-pdf-confirmation',
                    step:5,
                },
                {
                    title: 'front-stepper-title-checkout',
                    step:6,
                },
                // {
                //     title: 'front-stepper-title-signature',
                //     step:7,
                // }
            ]
            ,
            progresses: {
                formStep1: {
                    timing: 10,
                    percentage: 0
                },
                formStep2: {
                    timing: 8,
                    percentage: 20
                },
                formStep3: {
                    timing: 7,
                    percentage: 40
                },
                formStep4: {
                    timing: 6,
                    percentage: 60
                },
                formStep5: {
                    timing: 5,
                    percentage: 70
                },
                formStep6: {
                    timing: 4,
                    percentage: 80
                },
                // signature: {
                //     timing: 1,
                //     percentage: 90
                // }
            },
        }
    },

    created() {
        // if (window.location.hash.indexOf('nouveau-dossier') !== -1) {
        //     this.$emit('clearDossier');

        // }


    },
    methods: {
        updateProgress($event){
            this.progress = $event
            if(this.progress.actualStep > 0){
                this.progress.percentage = this.progresses['formStep' + (this.progress.actualStep )].percentage
                this.progress.timing = this.progresses['formStep' + (this.progress.actualStep)].timing
            }
        },
        saveForm(event) {
            this.$emit('saveForm', event)



        },
        previousEvent(event) {
            // console.log(event.previousRoute)
            this.$router.push({ name: event.previousRoute })
        }
    },

    mounted() {
        // this.loadDossier(()=>{
        //     this
        //   .data
        //   .initializeDataWithFormObject(this.dossier)
        // })
    }


}
</script>
