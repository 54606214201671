export default class Tribunal {
  constructor (code = '', name='') {
    this.code = code
    this.name = name
    this.rules = {
      code: [
        value => !!value || 'Ce champ est obligatoire'
      ]
    }
  }
  isComplete(){
    return (this.code)
  }
  static isStaticComplete(tribunal){
    return (tribunal.code)
  }
}
