<template>
  <div
    :key="'infraction-category-' + infractionCategory.id"
    class="infraction-category"
  >
    <h5 class="infraction-category-title text-white">
      {{ infractionCategory.special ? "Pour tout autre type d’infraction, cliquez ici" : infractionCategory.label }}
    </h5>
    <h6 class="infraction-category-subtitle mb-2 text-white font-italic">
      {{ infractionCategory.description }}
    </h6>
  </div>
</template>

<script>

export default {
  name: 'Category',
  model: {
    // prop: "infraction",
    // event: "completed"
  },
  props: [
    'infractionCategory'
    // 'formStepToShow'
  ],
  data () {
    return {
    // infractionCategory: {}
    }
  },
  methods: {

  }
}
</script>
