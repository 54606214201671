export default class Prejudice {
  constructor (personnel = '', preuves = '') {
    this.personnel = personnel

    this.preuves = preuves

    this.rules = {
      personnel: [
        value => !!value || 'Ce champ est obligatoire'
      ],
      preuves: [
        value => !!value || 'Ce champ est obligatoire'
      ]
    }
  }
  static isStaticComplete(item){
    return (item.preuves &&
      item.personnel)
  }
  isComplete(){
    return (this.preuves &&
      this.personnel)
  }
}
