<template>
    <div>
    <h1 class="text-center mb-sm-5 mt-sm-12 mx-sm-0 mt-5 mx-5">
        Porter plainte en ligne
    </h1>
    <div v-if="($route.name === 'home') || (progress && ((progress.actualStep == 1 || progress.actualStep == 0)))" class="mx-auto px-sm-3   " :class="{ 'card-container text-center': ($route.name !== 'home') }">
                                
        <h2 class="baseline d-none">
            <b>SIMPLE</b>,
            <b>RAPIDE</b>
            et
            <b>INTUITIF</b>
        </h2>
        <p>
            La transmission de votre plainte prend
            <b>moins de 10 minutes</b>
            : vous préparez votre plainte en ligne, relisez, validez et moyennant un
            <b>frais fixe de 35 €</b>, vous
            <b>signez électroniquement</b>
            l’acte qui est immédiatement adressé
            <b>par lettre recommandée avec accusé de réception</b>
            au procureur de la République compétent.
        </p>
        <div class="is-divider" />

    </div>
</div>
</template>
<script>

export default {
    props: [
        'progress'
    ],
    components: {

    },
    data() {
        return {

        }
    },
    watch: {

    },
    created() {

    },
    methods: {

    }
}
</script>