export default class Qualification {
  isComplete(){
    return (this.date &&
      this.expose &&
      this.code )
  }
  static isStaticComplete(item){
    return (item.date &&
      item.expose &&
      item.code )
  }
  constructor (infraction = '', fullAddress = '', address = '', code = '', city = '', date = '', expose = '') {
    this.infraction = infraction

    this.fullAddress = fullAddress

    this.address = address

    this.code = code

    this.city = city

    this.date = date

    this.expose = expose

    this.rules = {
      infraction: [
        value => !!value || 'Ce champ est obligatoire'
      ],
      fullAddress: [
        value => !!value || 'Ce champ est obligatoire'
      ],
      address: [
        value => !!value || 'Ce champ est obligatoire'
      ],
      code: [
        value => !!value || 'Ce champ est obligatoire',
        value => /^[0-9]{5}$/.test(value) || "Le code postal n'est pas valide"
      ],
      city: [
        value => !!value || 'Ce champ est obligatoire'
      ],
      date: [
        value => !!value || 'Ce champ est obligatoire',
        value => /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(value) || 'La date entrée est invalide'
      ],
      expose: [
        value => !!value || 'Ce champ est obligatoire'
      ]
    }
  }
}
