<template>
    <div class="card mx-auto my-8 px-0 px-sm-3 card-container">
        <div class="card-body">
            <div class="row">
                <div class="col-12 text-center">
                    <v-form @submit="logoutDossier" action="https://vuejs.org/" method="post">

                        <div class="form-group">
                            <label for="form_id">Vous êtes sur le point de vous déconnecter dossier en cours</label>

                        </div>
                        <input type="hidden" name="logout-data" value="none">
                        <button
                            class="font-weight-bold rounded-50 btn btn-block btn-primary text-white"
                            type="submit"
                        >
                        Se deconnecter du dossier en cours
                        </button>

                    </v-form>
                </div>
            </div>
        </div>
    </div>


</template>
<script>
import Login from './Login.vue'
import Display from './Display.vue'
export default {
    props: [
        "progress",
        "data"
    ],
    components: {
        Login,
        Display
    },
    data() {
        return {
            form: null,
            loaded: false,
            tribunal: null
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {
        // if(localStorage.getItem('__data_access_token')){
        //     this.loadDossier(()=>{
        //         this.loaded = true;
        //         this.getTribunal()
        //     });
        // }
        // else{
        //     this.loaded = true;
        // }
    },
    methods: {
        logoutDossier(e) {
            e.preventDefault();

            this.$emit('clearDossier');
            this.$router.push({ name: this.$route.params.fromDisplay ? 'my-display' : 'infraction' })

        },


    }
}
</script>
