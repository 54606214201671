<template>
   <div>
                    <div class="row justify-content-center">
                        <div class="col-md-12 mx-auto">
                            <div class="row">
                                <div v-for="infractionElementParent in filtersNotSpecial()"
                                    :key="'infractions-' + infractionElementParent.id"
                                    class="col-6 col-md-3 button-category">
                                    <button type="button" class="h-100 btn btn-primary col-12 infraction-category"
                                        :class="{ 'selected-category': (infractionParentData && (infractionParentData.id == infractionElementParent.id)) }"
                                        @click="openInfraction(infractionElementParent, $event)">
                                        <category :infraction-category="infractionElementParent" />
                                    </button>
                                </div>
                            </div>
                            <div v-for="infractionElementParent in filtersSpecial()"
                                :key="'special-infractions-' + infractionElementParent.id"
                                class="row justify-content-center">
                                <div :key="'special-other-infractions-' + infractionElementParent.id"
                                    class="col-12 col-md-6 button-category justify-content-center">
                                    <button type="button" class="h-100 btn btn-warning col-12 infraction-category"
                                        :class="{ 'selected-special-category': (infractionParentData && (infractionParentData.id == infractionElementParent.id)) }"
                                        @click="openInfraction(infractionElementParent, $event)">
                                        <category :infraction-category="infractionElementParent" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="(infraction_open && infractionParentData && !infractionParentData.special && infractionParentData.id)"
                        :key="'sub-infractions-' + infractionParentData.id"
                        class="row justify-content-md-center container-sub-infraction">
                        <div class="col-12">
                            <v-card class="advice" outlined>
                                <v-card-text>
                                    <span>Vous avez selectionné : <span class="font-weight-bold">{{
                                            infractionParentData.label
                                    }}.</span></span><br>
                                    <span>Veuillez préciser le type d'infraction ou modifier votre choix dans la liste
                                        <span class="link text-primary font-weight-bold"
                                            @click="scrollToTop()">ci-dessus</span>.</span>
                                </v-card-text>
                            </v-card>
                        </div>

                        <div v-if="infractionParentData" v-for="item in filtersChildren()"
                            :key="'sub-infraction-' + infractionParentData.id + '-item-' + item.id"
                            class="button-item  infraction-category-item col-md-12 mx-auto">
                            <button type="button"
                                :class="{ 'selected-item': infractionData && (infractionData.id == item.id) }"
                                class="h-100 btn btn-warning col-6 col-sm-10 btn-block infraction-items mx-auto"
                                @click="subInfractionSelectedFct(item, $event)">
                                <item :item="item" />
                            </button>
                        </div>
                    </div>
                    <div class="row justify-content-center" v-if="isMyDossier()">
                        <div class="col-md-6">
                            <button
                            class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                            @click="showPrevious($event)"
                            >
                            Précédent
                            </button>
                        </div>
                    </div>
                    <!-- <div class="col-12" v-if="sub_infraction_selected">
                          <v-card
                              class="advice"
                              outlined
                          >

                              <v-card-text>
                                  <span>Vous avez choisi de déposer une plainte pour : <span class="font-weight-bold">{{ current_infraction.label }} - {{ current_sub_infraction.label }}</span></span><br>
                                  <span>Si vous souhaitez changer de motif, <span class="link text-primary font-weight-bold" @click="scrollToTop()">cliquez ici</span> sinon cliquez sur le bouton suivant ci-dessous</span><br>
                              </v-card-text>

                          </v-card>
                      </div>
                      <div class="row mt-6" :key="'infractions-validate' + current_sub_infraction.id" v-if="sub_infraction_selected" >
                          <div class="col-md-6 mx-auto infraction-validation">
                              <button class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase" @click="validateFormInfraction($event)">Suivant</button>
                          </div>
                      </div> -->
                </div>

</template>
<script>
import Infraction from '../../../models/infraction'
import InfractionParent from '../../../models/infractionParent'
import Category from './infraction/Category'

import Item from './infraction/Item'
export default {
    components: {
        Category,
        Item
    },
    model: {
        // prop: "infractions",
        // prop: "subInfractions"
        // event: "completed",

    },
    props: [
        'infractionsItems',
        'data',
        'infraction'
        // 'subInfractions'
        // 'formStepToShow'
    ],
    data() {
        return {
            previousRoute:'',
            nextRoute:'facts',
            infraction_open: false,
            sub_infraction_selected: false,
            infractionParentData: new InfractionParent(),
            current_sub_infraction: null,
            infractionData: new Infraction()
        }
    },
    computed: {

    },
    created() {
        //   this.getInfractions()
        // this.infractionData = new Infraction()
        // infractionParentData = new InfractionParent()
        // if(data.infraction && value.infraction.id){
        //     this.infractionParentData = value.infraction.parent
        //     this.infractionData = value.infraction
        // }
        // console.log('bbbb',this.infraction)
        if (this.infraction && this.infraction.id) {

            // let temp = this.searchInfractionById(this.infraction.id)
            // this.openInfraction(temp.parent, null)
            // // this.infractionParentData = value.parent
            // // this.subInfractionSelectedFct()
            // this.infractionData = this.infraction

        }
    },
    watch: {
        // infraction(value) {
        //     // console.log('aa',value)
        //     if (value.id) {
        //         let temp = this.searchInfractionById(value.id)
        //         this.openInfraction(temp.parent, null)
        //         // this.infractionParentData = value.parent
        //         // this.subInfractionSelectedFct()
        //         this.infractionData = value
        //     }
        // }
    },
    methods: {
        filtersChildren() {
            ;
            return this.infractionParentData.children.slice().sort((a, b) => a.order - b.order)
        },
        filtersSpecial() {

            return this.infractionsItems.slice().sort((a, b) => a.order - b.order).filter(item => item.special === true)
        },
        filtersNotSpecial() {
            return this.infractionsItems.slice().sort((a, b) => a.order - b.order).filter(item => item.special === false)
        },
        searchInfractionById(id){

            for (let index = 0; index < this.infractionsItems.length; index++) {
            const elementParent = this.infractionsItems[index];

            for (let indexInfraction = 0; indexInfraction < elementParent.children.length; indexInfraction++) {
                const element = elementParent.children[indexInfraction];
                if(parseInt(element.id) === parseInt(id)){
                    element.parent = new InfractionParent(elementParent.id, elementParent.label, elementParent.children, elementParent.special)
                    return element;
                }
            }


            }
            return new Infraction();
        },
        subInfractionSelectedFct(subInfractionSelected, event) {
            if(event)
            event.preventDefault()
            this.infraction_open = true
            this.sub_infraction_selected = true
            this.infractionData = new Infraction(
                subInfractionSelected.id,
                subInfractionSelected.label,
                subInfractionSelected.placeholder,
                new InfractionParent(this.infractionParentData.id, this.infractionParentData.label)
            )
            // this.scrollToElement('.infractionData-validation')
            this.validateFormInfraction()
        },
        openInfraction(infractionItem, event, validate = true) {
            //https://local.plainte.qualiplainte.fr/?#/dossier
            if(event)
            event.preventDefault()


            this.infractionParentData = new InfractionParent(infractionItem.id, infractionItem.label, infractionItem.children, infractionItem.special)
            if (!infractionItem.special) {
                this.infractionData = new Infraction()
                this.infraction_open = true
                this.sub_infraction_selected = false
                this.scrollToElement('.container-sub-infraction')
            } else {
                this.infractionData = new Infraction(
                    this.infractionParentData.children[0].id,
                    this.infractionParentData.children[0].label,
                    this.infractionParentData.children[0].placeholder,
                    // infractionItem
                    new InfractionParent(this.infractionParentData.id, this.infractionParentData.label)
                )
                if(validate){
                // console.log(this.infractionData)
                this.validateFormInfraction()
                }
            }
        },
        //   getInfractions () {
        //     return this.infractions;
        //   },

        validateFormInfraction() {
            // window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'infraction-selected', value: 'Plainte en ligne : Infraction selectionne - étape 1' })


            // this.dossier.infraction = this.infractionData
            this.$emit('saveDossier', {
                'key': 'infraction',
                'data': this.infractionData,
                'nextRoute': this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.nextRoute
            })


        },
        isMyDossier(){
            return this.$route.name.indexOf('my-') > -1
        },
        showPrevious(event){
            event.preventDefault()
            this.$emit('previous',{
                'previousRoute':'my-display'
            })
        }
    },
    mounted() {
        window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'selection-infraction', value: 'Plainte en ligne : Selection Infraction' })

        let temp = this.searchInfractionById(this.infraction.id)
        this.openInfraction(temp.parent, null, false)
        // this.infractionParentData = value.parent
        // this.subInfractionSelectedFct()
        if(!temp.parent.special){
            this.infractionData = this.infraction
        }

        this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 0
        },)

    }
}
</script>
<style scoped>
.link {
    cursor: pointer;
}

.selected-category {
    background-color: #50aaa9;
    border-color: #4ca1a0;
    /* filter: alpha(opacity=50);
          opacity: .5; */
}
.selected-special-category{
    background-color: #d57700;
    border-color: #c87000;
}
.selected-item {

    background-color: #d57700;
    border-color: #c87000;
    /* filter: alpha(opacity=50);
          opacity: .5; */
}

.option-button {
    height: 100%;
}

.button-category {
    height: 110px;
}
</style>
