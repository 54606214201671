<template>
    <v-scroll-x-transition mode="out-in" appear>

        <div class="card mx-auto my-8 px-0 px-sm-3 card-container">
            <div class="card-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <HeaderTitle></HeaderTitle>
                        </div>
                    </div>

                    <div class="row justify-content-center">

                        <div class="col-md-12">
                            <router-link v-if="!data.id" :to="{name:'infraction'}" class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase text-white">
                                Déposer plainte

                            </router-link>
                            <router-link v-if="data.id" :to="{name:'disconnect'}" class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase text-white">
                                Déposer plainte

                            </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                Si vous souhaitez accèdez à une précédente plainte déposée via notre service,<br>
                                munissez vous de votre numéro de dossier ainsi que de la référence de celui-ci et cliquez sur le bouton ci-dessous
                            </p>
                        </div>
                    </div>

                    <div class="row justify-content-center">

                        <div class="col-md-12">
                            <router-link :to="{name:'my-display'}" class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase text-white">
                                Accèder à mon dossier

                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </v-scroll-x-transition>
</template>
<script>
import HeaderTitle from './header/HeaderTitle.vue';


export default {
    props: [
        "progress",
"data"
    ],
    components: {
    HeaderTitle
},
    data() {
        return {

        }
    },
    watch: {

    },
    created() {

    },
    methods: {

    },
    mounted(){
        // console.log('home')
        this.loadedFull = true
    }
}
</script>
