<template>
    <div>

                <div class="row justify-content-end">
                    <div class="col-4">
                        <router-link class="font-weight-bold rounded-50 btn btn-block btn-primary text-white" :to="{name:'disconnect', params:{fromDisplay:true}}" >
                            Changer de dossier
                        </router-link>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <FormDetails :data="data"></FormDetails>
                    </div>
                </div>
                <div class="card mt-5">
                    <div class="card-body">
                        <FormPersonnalData :data="data"></FormPersonnalData>
                    </div>
                </div>
                <div class="card mt-5">
                    <div class="card-body">
                        <FormInfraction :data="data"></FormInfraction>
                    </div>
                </div>
                <div class="card mt-5">
                    <div class="card-body">
                        <FormFacts :data="data"></FormFacts>
                    </div>
                </div>
                <div class="card mt-5" v-if="isCompleteQualification()">
                    <div class="card-body">
                        <FormTribunal v-model="data" ></FormTribunal>
                    </div>
                </div>

                <div class="card mt-5">
                    <div class="card-body">
                        <FormPrejudice :data="data"></FormPrejudice>
                    </div>
                </div>


    </div>

</template>
<script>

import FormState from './Display/FormState.vue'
import FormDetails from './Display/FormDetails.vue'
import FormPersonnalData from './Display/FormPersonnalData.vue';
import FormFacts from './Display/FormFacts.vue';
import FormPrejudice from './Display/FormPrejudice.vue';
import FormInfraction from './Display/FormInfraction.vue';
import FormTribunal from './Display/FormTribunal.vue';
import CustomFormData from '../../../models/customFormData';
import Qualification from '../../../models/qualification';
export default {
    model:{
        prop:'data'
    },
    props: [
        "progress",
        "data",
        "tribunal"
    ],
    components: {
    FormDetails,
    FormState,
    FormPersonnalData,
    FormFacts,
    FormPrejudice,
    FormInfraction,
    FormTribunal
},
    data() {
        return {
            form:new CustomFormData()
        }
    },
    watch: {
        data(value){
            if(value && value.id){
                this.form = data
            }
        }
        // title (value) {
        //     this.$forceUpdate()
        // }
    },
    created() {

    },
    methods: {
        isCompleteQualification(){
            return this.data.qualification && Qualification.isStaticComplete(this.data.qualification)
        }



    }
}
</script>
