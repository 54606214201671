<template>
    <div>

        
        <div class="row" v-if="((parseInt(data.state) > 0) || (getFormState().complete))">
            <div class="col-4" style="font-weight:bold">
                Vos documents
            </div>
            <div class="col-8">
                <div class="row py-0  justify-content-center" >
                    <div class="col-6 my-2" style="font-weight:bold">
                        <button @click="openDraft($event)" class="font-weight-bold rounded-50 btn btn-block btn-primary text-white"                         >
                            Visualiser mon projet
                        </button>
        
                    </div>
                    <div class="col-6 my-2" style="font-weight:bold" v-if="parseInt(data.state) > 2 ">
                        <a  target="_blank"
                        :href="'/api/access/get-signed-pdf/'+getAccessTokenFromLocal()+'/plainte_signee.pdf'"
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-white">
                            Plainte signée
                        </a>
                    </div>
                </div>
                <div class="row py-0  justify-content-center" v-if="(data.are || data.cachet_poste)">
                    <div class="col-6 my-2" style="font-weight:bold" v-if="data.are">
                        <a  target="_blank"
                        :href="('/get-form-cachet-poste/' + data.id)"
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-white">
                            Cachet de la poste
                        </a>
        
                    </div>
                    <div class="col-6 my-2" style="font-weight:bold" v-if="data.are ">
                        <a  target="_blank"
                        :href="('/get-form-are/' + data.id)"
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-white">
                            ARE
                        </a>
                    </div>
                </div>
            </div>
        </div>
       
        
    </div>

</template>
<script>
import Coordonnees from '../../../../models/coordonnees';
import CustomFormData from '../../../../models/customFormData';
import Infraction from '../../../../models/infraction';
import Prejudice from '../../../../models/prejudice';
import Qualification from '../../../../models/qualification';
import Tribunal from '../../../../models/tribunal';
import FormAction from './FormAction.vue';


export default {
    model:{
        prop:'data'
    },
    props: [
        "progress",
        "data",
        "tribunal"
    ],
    components: {
        FormAction
    },
    data() {
        return {
            
        }
    },
    watch: {
        // data(value) {
        //     console.log(value)
        //     if (value && value.id) {
        //         this.form = data
        //     }
        // }
        // title (value) {
        //     this.$forceUpdate()
        // }
    },
    created() {

    },
    methods: {
        getAccessTokenFromLocal(){
            return localStorage.getItem('__data_access_token')
        },
        openDraft(event){
            event.preventDefault()
            this.$router.push({ name: 'visualisation', params:{fromDisplay:true} })
            // this.$route.params.fromDisplay
            // this.getDownloadFile('/api/access/get-draft-pdf/plainte_projet.pdf')
        },
        openSigned(event){

        },
        getDownloadFile(fullUrl){
            axios.get( fullUrl) .then(response => {
        // console.log(response)
                var blob = new Blob([response.data], { type: 'application/pdf' });

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob); // for IE
                }
                else {
                    var fileURL = URL.createObjectURL(blob);
                    var newWin = window.open(fileURL);
                    newWin.focus();
                    // newWin.reload();
                }
}).catch(error => {
  console.log(error)
});
    },
        getFormState(){
            let complete = 
            this.data.tribunal && 
            this.data.infraction && 
            this.data.qualification && 
            this.data.prejudice && 
            this.data.coordonnees && 
            Tribunal.isStaticComplete(this.data.tribunal)
            &&
            Infraction.isStaticComplete(this.data.infraction)
            &&
            Qualification.isStaticComplete(this.data.qualification)
            &&
            Prejudice.isStaticComplete(this.data.prejudice)
            &&
            Coordonnees.isStaticComplete(this.data.coordonnees)

            let etat = '';
            switch(parseInt(this.data.state)){
                case -1:
                case 0:
                    etat = 'Projet non validé'
                    break;
                case 1:
                    etat = 'Projet validé'
                    break;
                case 2:
                    etat = 'Payée mais non signée éléctroniquement'
                    break;
                case 3:
                    etat = 'En cours d\'envoi au service responsable des envois en AR'
                    break;
                case 4:
                    etat = 'En cours d\'envoi au tribunal compétent'
                    break;
                case 5:
                    etat = 'Plainte déposée au tribunal compétent'
                    break;
            }
            return {
                complete,
                etat
            }
        },
       

    }
}
</script>