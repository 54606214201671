<template>
  <div
    :key="'infration-item' + item.id"
    class="infraction-item"
  >
    <h5 class="infraction-item-title text-white">
      {{ item.label }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'Item',
  model: {
    // prop: "item",
    // event: "completed"
  },
  props: [
    'item'
    // 'formStepToShow'
  ],
  data () {
    return {
    // item: {}
    }
  },
  methods: {

  }
}
</script>
