import VueRouter, { createRouter, createWebHistory } from 'vue-router'



import MyForm from '../components/form/MyForm.vue'
import Display from '../components/form/MyForm/Display.vue'
import Disconnect from '../components/form/MyForm/Disconnect.vue'
import Login from '../components/form/MyForm/Login.vue'
import Form from '../components/form/Form.vue'
import FormFacts from '../components/form/edit/FormFacts.vue'
import FormPrejudice from '../components/form/edit/FormPrejudice.vue'
import FormInfraction from '../components/form/edit/FormInfraction.vue'
import FormPaymentSignature from '../components/form/edit/FormPaymentSignature.vue'
import FormVisualisation from '../components/form/edit/FormVisualisation.vue'
import FormPersonnalData from '../components/form/edit/FormPersonnalData.vue'
import FormTribunaux from '../components/form/edit/FormTribunaux.vue'
import Welcome from '../components/Welcome.vue'

window.currentForm = {}
const routes = [
    {
        path: '/dossier',
        component: Form,
        alias: '/nouveau-dossier',
        name: 'form',
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'type-infraction',
                component: FormInfraction,
                name: 'infraction',
                // props: { form: window.currentForm }
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'les-faits',
                component: FormFacts,
                name: 'facts',
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'prejudice',
                component: FormPrejudice,
                name: 'prejudice',
                // props: { form: window.currentForm }
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'coordonnees',
                component: FormPersonnalData,
                name: 'personnalData',
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'tribunal',
                component: FormTribunaux,
                name: 'tribunal',
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'visualisation',
                component: FormVisualisation,
                name: 'visualisation',
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'paiement-et-signature',
                component: FormPaymentSignature,
                name: 'payment',
            }
        ]
    },
    {
        path: '/mon-dossier',
        component: MyForm,
        name: 'myform',
        children: [
            {
                path: '/',
                component: Display,
                name: 'my-display',
                // props: { form: window.currentForm }
            },
            {
                path: 'type-infraction',
                component: FormInfraction,
                name: 'my-infraction',
                // props: { form: window.currentForm }
            },
            {
                path: 'les-faits',
                component: FormFacts,
                name: 'my-facts',
            },
            {
                path: 'prejudice',
                component: FormPrejudice,
                name: 'my-prejudice',
                // props: { form: window.currentForm }
            },
            {
                path: 'coordonnees',
                component: FormPersonnalData,
                name: 'my-personnalData',
            },
            {
                path: 'tribunal',
                component: FormTribunaux,
                name: 'my-tribunal',
            },
            {
                path: 'visualisation',
                component: FormVisualisation,
                name: 'my-visualisation',
            },
            {
                path: 'paiement-et-signature',
                component: FormPaymentSignature,
                name: 'my-payment',
            }
        ]
    },
    {
        path: '/deconnexion',
        component: Disconnect,
        name: 'disconnect',
    },
    {
        path: '/connexion',
        component: Login,
        name: 'login',
    },

    {
        path: '/',
        component: Welcome,
        name: 'home',
    }

]
const router = new VueRouter({
    routes // short for `routes: routes`
})


router.beforeEach((to, from, next) => {

    // react to route changes...
    // console.log(to, from)
    if (to.name === "payment") {

        if (document.getElementsByClassName) {
            let footer = document.getElementsByClassName('footer-container');
            if (footer.length > 0) {
                footer[0].style.display = 'none';
                // console.log('hiding footer')
            }

        }
        if (window.$crisp) {
            $crisp.push(['do', 'chat:hide']);
            // console.log('hiding crisp')
        }
    }
    else {
        if (document.getElementsByClassName) {
            let footer = document.getElementsByClassName('footer-container');
            if (footer.length > 0) {
                footer[0].style.display = 'block';
                // console.log('showing footer')
            }

        }
        if (window.$crisp) {
            $crisp.push(['do', 'chat:show']);
            // console.log('showing crisp')
        }
    }

    // if the user is not authenticated, `next` is called twice
    next()
})
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
export default router


