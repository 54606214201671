import InfractionParent from "./infractionParent"

export default class Infraction {
  constructor (id = '', label = '', placeholder = [], parent = new InfractionParent()) {
    this.id = id

    this.label = label
    this.parent = parent

    
    this.placeholder = placeholder

    this.rules = {

    }
  }
  isComplete(){
    return (this.id)
  }
  static isStaticComplete(item){
    return (item.id)
  }
}
