<template>
    <div>

        <div class="row">
            <div class="col-12 text-bold">
                <h4><span>Les faits </span>
                    <span v-if="!isComplete()" class="text-warning">(incomplet)</span>
                    <span v-if="isComplete()" class="text-primary">(complet)</span>
                </h4>
            </div>

        </div>

        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Date
            </div>
            <div class="col-8">
                {{ data.qualification.date }}
            </div>
        </div>

        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Lieu
            </div>
            <div class="col-8">
                {{ data.qualification.address }}, {{ data.qualification.code }} {{
                        data.qualification.city
                }}
            </div>
        </div>


        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Exposé des faits
            </div>
            <div class="col-8">
                {{ data.qualification.expose }}
            </div>
        </div>

        <FormAction :data="data" :link="'my-facts'" :text="'Modifier les faits >>'"></FormAction>
    </div>

</template>
<script>
import Qualification from '../../../../models/qualification';
import FormAction from './FormAction.vue';

export default {
    props: [
        "progress",
        "data",
        "tribunal"
    ],
    components: {
    FormAction
},
    data() {
        return {

        }
    },
    watch: {

        // title (value) {
        //     this.$forceUpdate()
        // }
    },
    created() {

    },
    methods: {

        isComplete() {
            return this.data.qualification && Qualification.isStaticComplete(this.data.qualification)
        }

    }
}
</script>
