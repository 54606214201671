<template>

        <v-form
          ref="thirdStepForm"
          v-model="valid"
          autocomplete="off"
          @input="customValidation"
        >
          <div class="row">
            <div class="col-12">
              <v-radio-group
                v-model="coordonnees.civilite"
                row
                class="my-0"
                label="Civilité"
                hide-details="auto"
                :rules="coordonnees.rules.civilite"
              >
                <v-radio
                  name="civilite"
                  value="madame"
                >
                  <div
                    slot="label"
                    class="mb-n3"
                  >
                    {{ "form-step-three-label1" | translate }}
                  </div>
                </v-radio>
                <v-radio
                  name="civilite"
                  value="monsieur"
                >
                  <div
                    slot="label"
                    class="mb-n3"
                  >
                    {{ "form-step-three-label2" | translate }}
                  </div>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="col-sm-6">
              <v-text-field
                v-model="coordonnees.firstName"
                :label="translate('form-step-three-label3')"
                :rules="coordonnees.rules.firstName"
                hide-details="auto"
                outlined
                rounded
                class="secondary-input-bg"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
              />
            </div>
            <div class="col-sm-6">
              <v-text-field
                v-model="coordonnees.lastName"
                :label="translate('form-step-three-label4')"
                :rules="coordonnees.rules.lastName"
                hide-details="auto"
                outlined
                rounded
                class="secondary-input-bg"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
              />
            </div>
            <div class="col-12">
              <v-text-field
                v-model="coordonnees.company"
                :label="translate('form-step-three-label10')"
                :rules="coordonnees.rules.company"
                hide-details="auto"
                :persistent-hint="true"
                hint="Si vous agissez pour le compte d’une personne morale, indiquez son nom ainsi que sa forme (facultatif)"
                outlined
                rounded
                class="secondary-input-bg"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
              />
            </div>
            <div class="col-12">
              <v-text-field
                v-model="coordonnees.address"
                :label="translate('form-step-three-label5')"
                :rules="coordonnees.rules.address"
                hide-details="auto"
                outlined
                rounded
                class="secondary-input-bg"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
              />
            </div>
            <div class="col-md-4 col-sm-6">
              <v-text-field
                v-model="coordonnees.code"
                :label="translate('form-step-three-label6')"
                :rules="coordonnees.rules.code"
                hide-details="auto"
                outlined
                rounded
                class="secondary-input-bg"
                :error-messages="cityInputError"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
                @input="codeChanged($event)"
              />
            </div>
            <div class="col-md-8 col-sm-6">
              <v-combobox
                v-model="coordonnees.city"
                :rules="coordonnees.rules.city"
                :disabled="disableCityInput"
                :items="cities"
                item-text="name"
                item-value="name"
                :label="translate('form-step-three-label7')"
                hide-details="auto"
                :persistent-hint="true"
                outlined
                rounded
                clearable
                no-data-text="Aucune ville trouvée !"
                class="secondary-input-bg"
              />
            </div>
            <div class="col-12">
              <v-text-field
                v-model="coordonnees.phone"
                :label="translate('form-step-three-label8')"
                :rules="coordonnees.rules.phone"
                hide-details="auto"
                outlined
                rounded
                class="secondary-input-bg"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
              />
            </div>
            <div class="col-12">
              <v-text-field
                v-model="coordonnees.email"
                style="border-color:red !important"
                type="email"
                :label="translate('form-step-three-label9')"
                :rules="coordonnees.rules.email"
                hide-details="auto"
                outlined
                rounded
                class="secondary-input-bg"
                aria-autocomplete="none"
                autocomplete="off"
                @keypress="validateEnter($event)"
              />
            </div>
            <div class="col-12 py-0">
              <v-checkbox
                v-model="coordonnees.majeur"
                hide-details="auto"
                :rules="coordonnees.rules.majeur"
              >
                <div
                  slot="label"
                  class="mb-n3"
                >
                  {{ "form-step-three-checkbox1" | translate }}
                </div>
              </v-checkbox>
            </div>
            <div class="col-12 pt-0">
              <v-checkbox
                v-model="coordonnees.juridique"
                hide-details="auto"
                :rules="coordonnees.rules.juridique"
              >
                <div
                  slot="label"
                  class="mb-n3 checkbox-label"
                  @click.stop
                  v-html="translate('form-step-three-checkbox2')"
                />
              </v-checkbox>
            </div>
            <div
              v-if="showErrors"
              class="col-12 text-danger text-center"
            >
              {{ "error-general-validations" | translate }}
            </div>
            <div class="col-12">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6">
                    <button
                      class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                      @click="showPrevious($event)"
                    >
                      Précédent
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase"
                      @click="validate($event)"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>

  </template>
  <script>
  import Coordonnees from '../../../models/coordonnees'
  export default {
    model: {
      prop: 'coordonnees',
      event: 'validated'
    },
    props: ['coordonnees'],
    data () {
      return {
        previousRoute:'prejudice',
        nextRoute:'visualisation',
        alternateNextRoute:'tribunal',
        disableCityInput: true,
        cityInputError: '',
        cities: [],
        valid: true,
        showErrors: false,
        data: new Coordonnees()
      }
    },
    mounted () {
      this.setLogoLinkOnClick(this.showPrevious)
      this.scrollToTop()
      this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 3
        },)
    },
    beforeMount () {
    //   this.data = this.coordonnees
      if (this.coordonnees.code) {
        if (!this.coordonnees.city) {
          this.getCities()
        }
      }
    },
    methods: {
      getCities () {
        this.disableCityInput = true
        axios
          .get(
            '/api/communes/get-communes-by-postal-code/' +
                          this.coordonnees.code
          )
          .then(response => {
            if (response.data.status) {
              this.disableCityInput = false
              this.cityInputError = ''
              this.cities = response.data.communes
            } else {
              // this.cleanPostalCode();
              // this.cityInputError = 'Le code postal entré est incorrect';
              this.disableCityInput = false
              // this.cityInputError = '';
            }
          })
      },
      codeChanged ($event) {
        if (
          this.coordonnees.code &&
                  this.coordonnees.rules.code[1](this.coordonnees.code) === true
        ) {
          this.getCities()
        } else {
          this.cleanPostalCode()
        }
      },
      cleanPostalCode () {
        this.coordonnees.city = ''
        this.cities = []
      },
      validateEnter (event) {
        if (event.keyCode === 13) {
            this.validate()
        //   event.preventDefault()
        //   this.$refs.thirdStepForm.validate()
        //   if (this.valid) {
        //     window.gtrack({
        //       event: 'form_step',
        //       event_category: 'plainteenligne',
        //       event_label: 'step3',
        //       value: 'Plainte en ligne : étape 3'
        //     })
        //     if (this.coordonnees.city.name) {
        //       this.coordonnees.city = this.coordonnees.city.name
        //     }
        //     this.$emit('validated', this.data)
        //   } else {
        //     this.showErrors = true
        //   }
        }
      },
      showPrevious (event) {
        event.preventDefault()
        this.$emit('previous',{
            'previousRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.previousRoute
        })
      },

      validate (event) {
        event.preventDefault()
        this.$refs.thirdStepForm.validate()
        if (this.valid) {
          window.gtrack({
            event: 'form_step',
            event_category: 'plainteenligne',
            event_label: 'step3',
            value: 'Plainte en ligne : étape 3'
          })
          if (this.coordonnees.city.name) {
            this.coordonnees.city = this.coordonnees.city.name
          }
          this.$emit('validated', this.coordonnees)
          this.$emit('saveDossier', {
                'key': 'coordonnees',
                'data': this.coordonnees,
                'nextRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.nextRoute,
                'alternateNextRoute': this.alternateNextRoute
            })
        } else {
          this.showErrors = true
        }
      },
      customValidation () {
        if (this.showErrors) {
          this.showErrors = !this.$refs.thirdStepForm.validate()
        }
      }
    }
  }
  </script>
