<template>

        <v-form
          ref="secondStepForm"
          v-model="valid"
          autocomplete="off"
          @input="customValidation"
        >
          <div class="row">
            <div class="col-12">
              <v-textarea
                v-model="prejudice.personnel"
                class="secondary-input-bg"
                :rules="prejudice.rules.personnel"
                :label="translate('form-step-two-label1')"
                hide-details="auto"
                :hint="translate('form-step-two-hint1')"
                :persistent-hint="true"
                :placeholder="translate('form-step-two-placeholder1')"
                rows="10"
                outlined
                rounded
                aria-autocomplete="none"
                autocomplete="off"
              />
            </div>
            <div class="col-12">
              <v-textarea
                v-model="prejudice.preuves"
                class="secondary-input-bg"
                :rules="prejudice.rules.preuves"
                :label="translate('form-step-two-label2')"
                hide-details="auto"
                :hint="translate('form-step-two-hint2')"
                :persistent-hint="true"
                :placeholder="translate('form-step-two-placeholder2')"
                rows="10"
                outlined
                rounded
                aria-autocomplete="none"
                autocomplete="off"
              />
            </div>
            <div
              v-if="showErrors"
              class="col-12 text-danger text-center"
            >
              {{ 'error-general-validations' | translate }}
            </div>
            <div class="col-12">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6">
                    <button
                      class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                      @click="showPrevious($event)"
                    >
                      Précédent
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase"
                      @click="validate($event)"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>

  </template>
  <script>

  import Prejudice from '../../../models/prejudice'
  export default {
    components: {

    },
    model: {
      prop: 'prejudice',
      event: 'validated'
    },
    props: [
      'prejudice'
    ],
    data () {
      return {
        previousRoute:'facts',
        nextRoute:'personnalData',
        valid: true,
        showErrors: false,
        data: new Prejudice(),
      }
    },
    mounted () {
      this.setLogoLinkOnClick(this.showPrevious)
      this.scrollToTop()
      this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 2
        },)
    },
    beforeMount () {
    //   this.data = this.prejudice
    },
    methods: {
      validate (event) {
        event.preventDefault()
        this.$refs.secondStepForm.validate()
        if (this.valid) {
          window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'step2', value: 'Plainte en ligne : étape 2' })
        //   this.$emit('validated', this.data)
            this.$emit('saveDossier', {
                'key': 'prejudice',
                'data': this.prejudice,
                'nextRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.nextRoute
            })
        } else {
          this.showErrors = true
        }
      },
      customValidation () {
        if (this.showErrors) {
          this.showErrors = !this.$refs.secondStepForm.validate()
        }
      },
      showPrevious (event) {
        event.preventDefault()
        this.$emit('previous',{
            'previousRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.previousRoute
        })

      }
    }
  }
  </script>
  <style>
  ::-webkit-input-placeholder {
      font-style: italic;
  }
  :-moz-placeholder {
      font-style: italic;
  }
  ::-moz-placeholder {
      font-style: italic;
  }
  :-ms-input-placeholder {
      font-style: italic;
  }
  </style>
