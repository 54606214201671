<template>
    <div>

        <div class="row">
            <div class="col-12">
                <h4><span>Préjudice </span>
                    <span v-if="!isComplete()" class="text-warning">(incomplet)</span>
                    <span v-if="isComplete()" class="text-primary">(complet)</span>
                </h4>
            </div>

        </div>

        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Préjudice personnel
            </div>
            <div class="col-8">
                {{ data.prejudice.personnel }}
            </div>
        </div>
        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Preuves
            </div>
            <div class="col-8">
                {{ data.prejudice.preuves }}
            </div>
        </div>
        <FormAction :data="data" :link="'my-prejudice'" :text="'Modifier mon préjudice >>'"></FormAction>
    </div>

</template>
<script>
import Prejudice from '../../../../models/prejudice';
import FormAction from './FormAction.vue';

/*
accompagnement
:
0
are
:
null
audit_trail_pdf
:
"pdf/QP202105278612-1202-audit-trail.pdf"
cachet_poste
:
null
company
:
null
:
null
courrier_status
:
"envoyé_merci_facteur"
courrier_status_description
:
"[938304] - Courrier envoyé à Merci Facteur"
created_at
:
"2021-05-27T18:38:45.000000Z"
date
:
"16/04/2021"
document_id
:
"2739d7c6-48d4-4324-bd11-68a50ecb9292"
expedition_date
:
null
expose
:
"commande de 2 fauteuils numéro  commande 1736  noir et gris à coxa_france pour59.38 plus 3.80 port soit un total de 63.88 encaissé le 16/04/2021.  Je n'ai jamais rien reçu à ce jour. réclamation faite restée sana réponse"
id_envoi
:
"938304"
infraction
:
"Escroquerie"
pdf
:
"pdf/QP202105278612-8767.pdf"
prejudice_personnel
:
"63.88 euros"
prejudice_preuves
:
"sur le site il est indiqué fraude"
proof_sent
:
0
qualification_adress
:
"Méreau"
qualification_city
:
"Briosne-lès-Sables"
qualification_code
:
"72110"
qualification_full_adress
:
"Méreau cher,"
recommandation
:
0
tracking_number
:
null
tribunal_code
:
"72181"
*/
export default {
    props: [
        "progress",
        "data",
        "tribunal"
    ],
    components: {
        FormAction
    },
    data() {
        return {

        }
    },
    watch: {

        // title (value) {
        //     this.$forceUpdate()
        // }
    },
    created() {

    },
    methods: {
        isComplete() {
            return this.data.prejudice && Prejudice.isStaticComplete(this.data.prejudice)
        }


    }
}
</script>
