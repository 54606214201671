<template>
  <v-app class="bg-transparent">

    <div class="container-fluid bg-white shadow-sm">
      <div class="row">
        <div class="container py-0 py-md-2">
          <div class="row">
            <nav class="navbar navbar-expand-lg navbar-light bg-white w-100">
              <a id="default-logo-link" class="navbar-brand  " style="min-width:200px" href="https://qualiplainte.fr/" target="_blank">
                <img class="img-fluid w-100" src="/image/logo.svg" alt="Logo Qualiplainte" style="max-width:220px;"></a>

              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

                <ul class="navbar-nav ml-auto d-md-flex" style="min-width:fit-content;">
                  <li class="nav-item" id="accueil">
                    <router-link class="nav-link footer-link" :to="{name:'home'}">Accueil</router-link>
                  </li>
                  <li class="nav-item" id="mon-dossier">
                    <router-link class="nav-link footer-link" :to="{name:'my-display'}">Accèder à mon dossier</router-link>
                  </li>
                  <li class="nav-item" id="nouveau-dossier">
                    <router-link v-if="!dossier.id" class="nav-link footer-link" :to="{name:'infraction'}">Nouvelle plainte
                      </router-link>
                    <router-link v-if="dossier.id" class="nav-link footer-link" :to="{name:'disconnect'}" >Nouvelle plainte
                      </router-link>
                  </li>
                  <li class="nav-item" id="return-website-link">
                    <a class="nav-link footer-link" href="https://qualiplainte.fr/" target="_blank">Retourner sur Qualiplainte.fr</a>

                  </li>
                </ul>

              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- route outlet -->
    <!-- component matched by the route will render here -->
    <div class="container-fluid bg-white shadow-sm">
      <div class="row">
        <div class="col-12">

        <v-scroll-x-transition mode="out-in">
          <div v-if="!loadedFull" class="row justify-content-center">
        <div class="col-2 justify-content-center">
          <div  class=" spinner-border text-dark spinner-custom" style="width: 4rem !important; height: 4rem !important;"
                  role="status">
                  <span class="sr-only text-dark">Chargement ...</span>
              </div>
              </div>
              </div>
              <router-view v-if="loadedFull" :data="dossier" :infraction="dossier.infraction" :infractionsItems="infractions"
        :tribunauxItems="tribunaux" @clearDossier="clearDossier()" @loginLoadDossier="loadDossier()"
        @saveForm="saveAll($event)">
      </router-view>


    </v-scroll-x-transition>
  </div>
    </div>
    </div>

  </v-app>
</template>

<script>

import CustomFormData from './models/customFormData'
import { RouterView } from 'vue-router';

export default {
  computed: {


  },
  components: {
    RouterView

  },
  data() {
    return {

      // refacto

      data: new CustomFormData(),
      infractions: [],
      titles: {
        formStepInfraction: 'front-title-infraction-step-form',
        formStep1: 'front-title-first-step-form',
        formStep2: 'front-title-second-step-form',
        formStep3: 'front-title-third-step-form',
        formStep4: 'front-title-fourth-step-form',
        pdfConfirm: 'front-title-pdf-confirmation',
        checkout: 'front-title-checkout',
        signature: 'front-title-signature'
      },
      title: '',



      //old
  // progress: {
      //   timing: '',
      //   percentage: 0
      // },
      generateDocument: false,
      showForm: false,
      showInfractions: true,
      formStepToShow: 0,
      showPdfConfirmation: false,
      showCheckoutModal: false,
      showSignatureModal: false,

      showPreview: false,


      // steps: 7,
      formReference: '',


      dossier: new CustomFormData(),
      tribunaux: [],
      infractions: [],
      loadedFull: false
    }
  },
  watch: {
    title(value) {
      this.$forceUpdate()
    },


  },
  created() {


    this.initRequests(() => {
      this.autoLogin();
    })
  },

  methods: {

    getCurrentDossier() {
      return this.dossier
    },
    autoLogin() {

      const queryString = window.location.search

      const urlParams = new URLSearchParams(queryString)
      const id = urlParams.has('dossier')
        ? urlParams.get('dossier')
        : null
      let ref = urlParams.has('reference')
        ? urlParams.get('reference')
        : null
      urlParams.delete('dossier')
      urlParams.delete('reference')

      let autoLogin = null;
      if (id && ref) {
        try {

          window.atob(ref);
        } catch (e) {
          // something failed
        //   // console.log(e)
          // if you want to be specific and only catch the error which means
          // the base 64 was invalid, then check for 'e.code === 5'.
          // (because 'DOMException.INVALID_CHARACTER_ERR === 5')
          ref = window.btoa(ref);
        }


        // https://local.plainte.qualiplainte.fr/?dossier=49&reference=$2y$10$e9krP2O2HaEMRnmDCIDrmuXP4DyD6uGNDTMrI8/j.UztVug3otUri

        let urlAccessToken = `/api/access-token-from-hash/${id}/${ref}`

        if (urlAccessToken) {
          axios.get(urlAccessToken)
            .then(response => {

              history.replaceState(null, '', (urlParams ? '?' + urlParams : '') + location.hash)
              this.loadedFull = true
              localStorage.setItem('__data_access_token', response.data['access-token']);
              this.loadDossier(() => {
                this.$router.push({ name: 'my-display' })
              });
            }).catch(error => {
            //   // console.log('error', error)
            })
        }

      }
      else {

        this.loadDossier();
      }
    },

    getAccessToken(_callback) {
      //localStorage.setItem('sessionId', form.id)
      let loginDossierData = this.getSessionParameters();
      let urlAccessToken = null;
      if (loginDossierData.id && loginDossierData.reference_hash) {
        urlAccessToken = '/api/access-token-from-hash/' + loginDossierData.id + '/' + loginDossierData.reference_hash
      }
      else if (loginDossierData.id && loginDossierData.reference) {
        urlAccessToken = '/api/access-token/' + loginDossierData.id + '/' + loginDossierData.reference

      }

      if (urlAccessToken) {
        axios.get(urlAccessToken)
          .then(response => {
            localStorage.setItem('__data_access_token', response.data['access-token']);
            // window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'signature_ready', value: 'Plainte en ligne : signature prête' })
            // // this.url = response.data.url
            // // this.loading = false
            // this.redirectToSignature(response.data.url)
            if (_callback) {
              _callback()
            }
          }).catch(error => {
            // // console.log(error)
          })
      }
    },
    initRequests(_callbackAfterInit) {
      this.getCrsf(
        () => {
          this.loadTribunaux(
            () => {
              this.loadInfractions(() => {
                //_callbackAfterInit
                this.autoLogin()
              }
              )
            }
          )
        }
      );
    },
    getCrsf(_callback) {

      // always use crsf before anything
      axios.get('/sanctum/csrf-cookie')
        .then(response => {
          if (_callback) {
            _callback()
          }

        }).catch(error => {
          // console.log(error)
        })
    },

    clearDossier() {
      localStorage.removeItem('__data_access_token');
      this.dossier.reinitializeData();
    },
    loadTribunaux(_callback) {

      let url = '/api/tribunaux/get-all'

      axios.get(url)
        .then((response) => {
          this.tribunaux = response.data.tribunaux
          if (_callback) {
            _callback()
          }

        }).catch(error => {

          // console.log(error)
        })


    },
    loadInfractions(_callback) {
      let url = '/api/infractions'

      axios.get(url)
        .then((response) => {
          this.infractions = response.data
          if (_callback) {
            _callback()
          }

        }).catch(error => {

          // console.log(error)
        })
    },

    loadDossier(_callback) {
      if (this.dossier.initialized) {
        this.loadedFull = true
        if (_callback) {
          _callback()

        }
      }
      else if (localStorage.getItem('__data_access_token')) {
        let urlLoadForm = '/api/access/forms'

        if (urlLoadForm) {
          axios.get(urlLoadForm)
            .then((response) => {
              this.dossier.initializeDataWithFormObject(response.data.form, this.tribunaux, this.infractions);

              this.loadedFull = true
              // this.loaded = true;
              if (_callback) {
                _callback()
              }


            }).catch(error => {

              // console.log(error)
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                if ((error.response.data.error === 'Invalid access-token detected') && (error.response.status === 403)) {
                  localStorage.removeItem('__data_access_token')
                  window.location.reload()
                  // this.loadedFull = true
                  // this.loadDossier(() => {
                  //   this.$router.push({ name: 'home' })
                  // });
                }
              }

            })
        }
      }
        else {
          this.loadedFull = true
            this.$router.push({ name: 'home' })


      }
    },
    saveDossier(_callback) {
      let urlSaveForm = '/api/access/forms/store'
      let bCreate = false
      if (!this.dossier.id) {
        urlSaveForm = '/api/forms/create'
        bCreate = true
      }

      // console.log(this.dossier)
      let dataToStore = this.dossier.optimizeForStoring()
      // console.log(dataToStore)

      if (urlSaveForm) {
        axios
          .post(urlSaveForm, {
            id: this.dossier.id,
            data: dataToStore
          })
          .then(res => {
            if (bCreate) {
              // console.log(res.data)
              localStorage.setItem('__data_access_token', res.data.new_access_token)

              this.dossier.id = res.data.id
              this.dossier.reference = res.data.reference
              this.dossier.state = -1;
            }
            if (_callback) {
              _callback()
            }
          })
          .catch(error => {
            // console.log(error)
          })

      }
    },





    haveAccessToken() {
      return localStorage.getItem('__data_access_token') !== null;
    },
    saveAll(event) {
      let nextRoute = null
      switch (event.key) {
        case 'coordonnees':
          this.dossier.coordonnees = event.data
          nextRoute = event.nextRoute
          this.lookForTribunal(nextRoute, event.alternateNextRoute, () => {

          });
          break;
        case 'visualisation':
        nextRoute = event.nextRoute
            if (nextRoute) {
              this.nextRouteFct(nextRoute)

            }
            break;
        case 'tribunal':
        case 'qualification':

          // break;
        case 'infraction':
          // this.dossier.infraction = event.data
          // nextRoute = event.nextRoute
          // break;
        case 'prejudice':
          // this.dossier.prejudice = event.data
          // nextRoute = event.nextRoute
          nextRoute = event.nextRoute
          break;

        //   break;

      }

      if(this.dossier[event.key]){
        this.dossier[event.key] = event.data
        }
        else {
        for(let prop in event.data){
            if(this.dossier.hasOwnProperty(prop)){
            this.dossier[prop] = event.data[prop]
            }
        }
        }


        this.saveDossier(() => {
        if (nextRoute) {
            this.nextRouteFct(nextRoute)
        }
        })



    },
    nextRouteFct(nextRoute) {
      this.$router.push({ name: nextRoute })
    },
    lookForTribunal(nextRoute, alternateRoute,_callback) {
      axios
        .get('/api/tribunaux/' + (
          this.dossier.qualification.code
            ? this.dossier.qualification.code
            : null
        ))
        .then(response => {
          if (response.data.status) {
            this.dossier.tribunal.code = response.data.tribunalCode
            this.saveDossier(() => {
              if (nextRoute) {
                this.nextRouteFct(nextRoute)
              }
            })
          } else {
            // this.formChanged(4)
            this.saveDossier(() => {
              // console.log(alternateRoute)
              if (alternateRoute) {
                this.nextRouteFct(alternateRoute)
              }
            })
          }
        })
    },
  }
}
</script>
<style>

</style>
