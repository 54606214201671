<template>
    <div>
      <template v-if="!show">
        <v-progress-linear
          indeterminate
          color="primary"
          class="mx-auto"
        />
        <div class="mt-5 text-center text-primary">
          {{ 'pdf-confirmation-progress' | translate }}
        </div>
      </template>
      <div
        v-if="show"
        class="card-body scrollable-card text-center mb-8"
      >
        <h6 class="font-weight-medium">
          <em>{{ 'pdf-confirmation-subtitle' | translate }}</em>
        </h6>
        <template
          v-for="i in numPages"
        >
          <pdf
            :key="'pdf-page-' + i"
            :src="src"
            :page="i"
          />
          <v-divider
            v-if="numPages != i"
            :key="'divider-' + i"
          />
        </template>
      </div>
      <hr
        v-if="show"
        class="mb-5 mt-2"
      >
      <div
        v-if="show"
        class="p-2"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <button
                class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                @click="canceled"
              >
                {{ 'pdf-confirmation-button-previous' | translate }}
              </button>
            </div>
            <div class="col-md-6 ">
              <button
                class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase"
                @click="confirm"
              >
                {{ 'pdf-confirmation-button-next' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <v-scroll-y-transition
        mode="out-in"
        appear
      >
        <div
          v-if="show"
          class="d-flex d-md-none floating-button justify-content-center"
        >
          <button
            class="font-weight-bold btn-block rounded-50 btn btn-primary text-uppercase"
            @click="confirm"
          >
            {{ 'pdf-confirmation-button-next' | translate }}
          </button>
        </div>
      </v-scroll-y-transition> -->
    </div>
  </template>
  <script>
  import Pdf from 'vue-pdf'
  export default {
    components: {
      Pdf
    },
    props: [
      'path',
      'formId',
      'formReference'
    ],
    data () {
      return {
        previousRoute:'personnalData',
        nextRoute:'payment',
        src: null,
        numPages: null,
        show:true
      }
    },
    watch : {
        formId(value){
            this.initializePdf()
        },
        formReference(value){
            this.initializePdf()
        }
    },
    updated () {
      this.initializePdf()
    },
    mounted () {
      this.initializePdf()
    //   this.setLogoLinkOnClick(event => {
    //     event.preventDefault()
    //     this.canceled()
    //   })
    this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 5
        },)
    },
    methods: {
      confirm () {
        window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'pdf_confirmed', value: 'Plainte en ligne : PDF confirmé' })
        this.$emit('confirmed', this.path)
        this.$emit('saveDossier', {
            'key': 'visualisation',
            'data': {state:1},
            'nextRoute':this.nextRoute
        })
      },
      isMyDossier(){
            return this.$route.name.indexOf('my-') > -1
        },
      canceled () {

        this.$emit('previous',{
            'previousRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.previousRoute
        })
      },
      initializePdf () {
        if (this.show && !this.src && this.formId && this.formReference ) {

          this.src = Pdf.createLoadingTask('/get-draft-pdf/' + this.formId + '/' +this.formReference+ '.pdf')
          this.src.promise.then(pdf => {
            this.numPages = pdf.numPages
          })
        }
      }
    }
  }
  </script>
  <style scoped>
  @media (min-width : 720px) {
      .scrollable-card{
          max-height:70vh;
          overflow-y:auto;
      }
  }
  .floating-button{
      position: fixed;
      bottom: 0;
      z-index: 9999;
      background-color: white;
      width: 100%;
      left: 0;
      padding: 8px;
      box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  </style>
