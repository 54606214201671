<template>
    <div>
        <div v-if="!success" class="mx-auto my-8 px-5 px-sm-10 py-5">
            <div class="text-center">
                <!-- <h1 class="mb-4 mt-n2">{{'checkout-title' | translate}}</h1> -->
                <div v-if="show" class="mb-5">
                    <!-- <strong>{{'checkout-subtitle1' | translate}}</strong> -->
                    <!-- <div>{{'checkout-subtitle2' | translate}}</div> -->
                    <!-- <strong>Je souhaite finaliser immédiatement ma plainte en ligne et l’adresser directement au procureur de la République : {{}} €</strong> -->
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="pricing">
                                    {{ amount }} €
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="label-important">
                                    Édition de ma plainte en ligne, signature électronique et envoi au tribunal par
                                    lettre recommandée avec AR
                                </div>
                            </div>
                            <div class="col-12 pt-0">
                                <!-- <v-checkbox
                                      hide-details="auto"
                                      class="m-0 pointereventsnone"
                                      v-model="checked"
                                  >
                                      <div slot='label' @click.stop class="mb-n3 checkbox-label label-important" v-html="'Édition de ma plainte en ligne, signature électronique et envoi au tribunal par lettre recommandée avec AR (' + amountWithoutAccompagnement + ' €)'"></div>
                                  </v-checkbox> -->
                                <v-checkbox v-model="accompagnement" hide-details="auto" class="m-0">
                                    <div slot="label" class="mb-n3 checkbox-label" @click.stop
                                        v-html="'<b>Option :</b> accompagnement par un avocat dans mon dépôt de plainte <b>(+ 64 €)</b>'" />
                                </v-checkbox>
                                <v-checkbox v-model="recommandation" hide-details="auto" class="m-0">
                                    <div slot="label" class="mb-n3 checkbox-label" @click.stop
                                        v-html="'<b>Bonus :</b> recommandation d\'un avocat compétent proche de chez moi <b>(gratuit !)</b>'" />
                                </v-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="text-left lh-12">
                        <b>Nous nous occupons de tout :</b> vous recevez directement dans votre boite mail la copie de
                        votre plainte ainsi que la preuve de son envoi à la Justice. Sur simple demande et sans frais
                        supplémentaire, nous vous recommandons un avocat pénaliste pour le suivi de votre dossier.
                    </div>


                    <div class="border border-secondary text-left lh-12 mt-5 p-2 rounded">
                        Lorsque le paiement sera effectué et validé, vous serez redirigé automatiquement vers le service
                        de signature électronique de votre plainte.<br>
                        Votre plainte sera ensuite envoyée au tribunal par lettre recommandée avec AR.<br><br>
                        <b>Important !</b> Suite à cette dernière étape, il ne sera plus possible de modifier le contenu
                        de votre plainte.
                    </div>

                    <div class="text-left small mt-5">
                        Attention : le modèle de lettre qui vous est proposé n’a pas vocation à se substituer à des
                        conseils personnalisés qui pourraient vous être fournis par des professionnels du droit. Il a
                        pour objet de vous proposer une démarche que vous jugez pertinente au regard de votre situation.
                    </div>
                </div>
                <v-form id="payment-form" ref="cgvForm" v-model="valid">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-md-8">
                                <div id="card-element" class="card-input-style" />
                                <div id="card-errors" class="error--text mt-3 text-left" role="alert" />
                            </div>
                            <div class="col-md-4">
                                <img src="\image\stripe.png" alt="Stripe" class="img-fluid stripe-img"
                                    style="width:200px;">
                            </div>
                        </div>
                        <div class="row align-items-center flex-row-reverse">
                            <div class="col-12">
                                <v-checkbox v-model="cgv" hide-details="auto" :rules="rules" class="m-0">
                                    <div slot="label" class="mb-n3 checkbox-label" @click.stop
                                        v-html="translate('checkout-cgv-chebox')" />
                                </v-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between" />
                </v-form>
            </div>
            <hr class="mb-5 mt-2">
            <div class="p-2">
                <div class="container-fluid">
                    <div class="row justify-content-center">

                        <div class="col-md-12">
                            <button :disabled="loading || disableButton"
                                class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase"
                                @click="confirm">
                                <span v-if="!loading && !disableButton">{{ 'checkout-paiment-button' | translate
                                }}</span>
                                <div v-if="loading || disableButton" class="spinner-border text-white spinner-custom"
                                    role="status">
                                    <span class="sr-only">Chargement ...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <button class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                                @click="previousStep($event)">
                                Précédent
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="success" class="mx-auto my-8 px-5 px-sm-10 py-5">
            <div class="text-center">
                <h1 class="mb-8 mt-n2">
                    {{ 'checkout-project-success-title' | translate }}
                </h1>
                <v-icon class="mb-8" size="75px" color="success">
                    fas fa-check-circle
                </v-icon>
                <h3>Redirection vers la signature</h3>

            </div>

        </div>
    </div>
</template>
<script>
export default {
    props: [
        'formId'
    ],
    data() {
        return {
            previousRoute: 'visualisation',
            urlSignature: '',
            loadingSignature: false,
            recommandation: false,
            checked: true,
            disableButton: false,
            accompagnement: false,
            success: false,
            amountWithoutAccompagnement: '',
            amount: '',
            valid: true,
            loading: true,
            source: null,
            stripe: null,
            clientSecret: null,
            card: null,
            show: false,
            cgv: false,
            rules: [
                value => value || 'Veuillez confirmer que vous acceptez les conditions générales de vente'
            ]
        }
    },
    watch: {
        accompagnement(val) {
            this.getStripeIntent()
        }
    },

    unmounted(){

    },
    mounted() {
        if(document.getElementsByClassName){
            let footer = document.getElementsByClassName('footer-container');
            if(footer.length > 0){
                footer[0].style.display = 'none';
                // console.log('hiding footer')
            }

        }
        if(window.$crisp){
                $crisp.push(['do', 'chat:hide']);
                // console.log('hiding crisp')
            }
        const stripeLibrary = document.createElement('script')
        stripeLibrary.setAttribute('src', '//js.stripe.com/v3/')
        document.head.appendChild(stripeLibrary)

        this.setLogoLinkOnClick()
        this.getStripeIntent()
        window.gtrack({
            event: 'form_step',
            event_category: 'plainteenligne',
            event_label: 'payment_page',
            value: 'Plainte en ligne : page paiement'
        })
        this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 6
        },)
    },
    methods: {
        previousStep() {
            this.$emit('previous', {
                'previousRoute': this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.previousRoute
            })
        },
        validateId() {
            this.$emit('confirmed')
        },
        confirm(event) {
            // top.window.onbeforeunload = null;
            // top.window.onunload = null;
            this.$refs.cgvForm.validate()
            if (this.valid) {
                this.loading = true
                this.stripe.confirmCardPayment(this.clientSecret, {
                    payment_method: {
                        card: this.card
                    }
                }).then(result => {
                    if (result.error) {
                        this.loading = false
                        document.getElementById('card-errors').textContent = result.error.message
                    } else {
                        if (result.paymentIntent.status === 'succeeded') {
                            window.gtrack({
                                event: 'conversion',
                                send_to: 'AW-682224523/T1-6CLbnlckCEIvXp8UC',
                                value: result.paymentIntent.amount / 100,
                                currency: 'EUR',
                                transaction_id: result.paymentIntent.id
                            })

                            window.gtrack({
                                event: 'form_step',
                                event_category: 'plainteenligne',
                                event_label: 'payment_done',
                                value: 'Plainte en ligne : paiement effectué'
                            })
                            // window.gtrack('event', 'payment', {'event_category': "plainteenligne", 'event_label': "payment_plainteenligne", 'value': 'Porter plainte en ligne'});
                            window.btrack('event', 'payment', {
                                event_category: 'plainteenligne',
                                event_label: 'payment_plainteenligne',
                                event_value: (result.paymentIntent.amount / 100).toString(),
                                revenue_value: (result.paymentIntent.amount / 100).toString(),
                                currency: 'EUR'
                            })
                            window.gtrack({
                                event: 'purchase',
                                currency: 'EUR',
                                transaction_id: result.paymentIntent.id,
                                value: result.paymentIntent.amount / 100,
                                tax: (result.paymentIntent.amount / 100) - ((result.paymentIntent.amount / 100) / 1.2),
                                items: {
                                    item_id: ('QP_PLAINTEENLIGNE' + (this.recommandation ? '_RECO' : '') + (this.accompagnement ? '_ACCO' : '')),
                                    item_name: ('Plainte en ligne' + (this.recommandation ? ' + recommandation' : '') + (this.accompagnement ? ' + accompagnement' : '')),
                                    item_category: 'plainteenligne'
                                }
                            })

                            axios.post('/api/access/forms/update', {// + this.formId
                                recommandation: this.recommandation,
                                accompagnement: this.accompagnement,
                                checkoutId: result.paymentIntent.id,
                                checkoutAmount: result.paymentIntent.amount,
                                checkoutStatus: result.paymentIntent.status
                            })
                                .then(response => {
                                    this.success = response.status
                                    // this.validateId();
                                    // this.scrollToTop();
                                    // event.preventDefault()
                                    // this.validateId()

                                    setTimeout(() => {
                                        this.validateId()
                                        this.createSignature()
                                    }, 1000)

                                })
                                .catch(error => {
                                    // console.log('ERROR CheckoutModal.vue:203 [' + error.response + ']')
                                    // console.log(error)
                                })
                        }
                    }
                })
            }
        },
        createSignature() {
            axios.post('/api/access/signature/create')///' + this.formId
                .then(response => {
                    window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'signature_ready', value: 'Plainte en ligne : signature prête' })
                    // this.url = response.data.url
                    // this.loading = false
                    this.redirectToSignature(response.data.url)
                }).catch(error => {
                    // console.log(error)
                })
        },
        redirectToSignature(url) {
            // evt.preventDefault();
            window.location.replace(url);
            //  return false;
            // window.location.href = url
        },
        getStripeIntent() {
            this.disableButton = true
            axios.get('/api/access/checkout/' + this.accompagnement)
                .then(response => {
                    this.clientSecret = response.data.clientSecret
                    this.stripe = Stripe(response.data.publicKey)
                    this.amount = response.data.amount
                    this.amountWithoutAccompagnement = response.data.amountWithoutAccompagnement
                    const elements = this.stripe.elements({
                        locale: 'fr-FR'
                    })
                    const style = {
                        base: {
                            backgroundColor: '#F7F7F7',
                            color: 'rgba(0,0,0,.87)',
                            fontFamily: 'Lato, Helvetica, Arial, sans-serif',
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                                color: 'rgba(0,0,0,.6)'
                            }
                        },
                        invalid: {
                            color: '#ff5252 ',
                            iconColor: '#ff5252  '
                        }
                    }
                    this.card = elements.create('card', { hidePostalCode: true, style })
                    this.card.on('ready', event => {
                        this.loading = false
                        this.show = true
                    })
                    this.card.mount('#card-element')
                    this.card.on('change', ({ error }) => {
                        const displayError = document.getElementById('card-errors')
                        if (error) {
                            displayError.textContent = error.message
                        } else {
                            displayError.textContent = ''
                        }
                    })
                    this.disableButton = false
                }).catch(error => {
                    // console.log(error.response)
                })
        }
    }
}
</script>
<style scoped>
.checkbox-label {
    font-size: 14px;
}

.card-input-style {
    padding: 20px;
    border-radius: 50px;
    background-color: #f7f7f7 !important;
    border: 1px solid rgba(0, 0, 0, .3);
}

.stripe-img {
    width: 200px;
}

.pointereventsnone {
    pointer-events: none;
}

.label-important {
    color: #68b8b7;
    text-align: left;
    font-size: .9rem;
    font-weight: 600;
}

.pricing {
    height: 100%;
    background: #68b8b7;
    color: #fff;
    vertical-align: middle;
    font-weight: 700;
    font-size: 1.8rem;
    border-radius: 5px;
}

.small {
    font-size: 80%;
    font-style: italic;
}

.lh-12 {
    line-height: 1.2;
}

.card-body {
    padding: 0;
}
</style>
