<template>
    <div>


        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Saisie
            </div>
            <div class="col-8">
                <span v-if="!getFormState().complete " class="text-warning">Incomplete</span>
                <span v-if="getFormState().complete" class="text-primary">Complete</span>

            </div>
        </div>
        <div class="row">
            <div class="col-4" style="font-weight:bold">
                Etat de votre plainte
            </div>
            <div class="col-8">
                <div class="row py-0 my-0" >
                    <div class="col-12 py-0 my-0">
                        <span v-if="!data.state < 2 " class="text-warning">{{ getFormState().etat }}</span>
                        <span v-if="!data.state >= 2 " class="text-primary">{{ getFormState().etat }}</span>
                    </div>
                </div>
                <div class="row row py-0 my-0" v-if="parseInt(data.state) < 0 ">
                    <div class="col-12 small py-0 my-2" style="font-weight:bold">
                        Vous devez finaliser votre plainte et la signer éléctroniquement afin que nous puissions l'envoyer au tribunal compétent en AR
                    </div>

                </div>
                <div class="row py-0  justify-content-center" v-if="parseInt(data.state) < 0 ">
                    <div class="col-8 my-2" style="font-weight:bold">
                        <router-link
                        :to="{ name: 'facts', params:{fromDisplay:false} }"
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-white"  >
                            Finaliser et Signer ma plainte
                        </router-link>

                    </div>
                </div>
                <div class="row row py-0 my-0" v-if="parseInt(data.state) === 0 ">
                    <div class="col-12 small py-0 my-2" style="font-weight:bold">
                        Vous devez finaliser votre plainte et la signer éléctroniquement afin que nous puissions l'envoyer au tribunal compétent en AR
                    </div>

                </div>
                <div class="row py-0  justify-content-center" v-if="parseInt(data.state) === 0 ">
                    <div class="col-8 my-2" style="font-weight:bold">
                        <router-link
                        :to="{ name: 'payment', params:{fromDisplay:true} }"
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-white"  >
                            Finaliser et Signer ma plainte
                        </router-link>

                    </div>
                </div>
                <div class="row py-0 my-0" v-if="parseInt(data.state) === 1 ">
                    <div class="col-12 small py-0 my-2" style="font-weight:bold">
                        Vous devez signer éléctroniquement votre plainte, il est nécessaire de le faire afin que nous puissions l'envoyer au tribunal compétent en AR.<br>
                        Veuillez cliquer sur le lien ci-dessous afin de signer votre plainte.
                    </div>
                </div>
                <div class="row py-0  justify-content-center" v-if="parseInt(data.state) === 1 ">
                    <div class="col-8 my-2" style="font-weight:bold">
                        <router-link
                        :to="{ name: 'payment', params:{fromDisplay:true} }"
                        class="font-weight-bold rounded-50 btn btn-block btn-primary text-white"  >
                            Finaliser et Signer ma plainte
                        </router-link>

                    </div>
                </div>
                <div class="row py-0 my-0" v-if="parseInt(data.state) === 2 ">
                    <div class="col-12 small py-0 my-2" style="font-weight:bold">
                        Vous avez effectué le paiement mais vous n'avez pas signer éléctroniquement votre plainte, il est nécessaire de le faire afin que nous puissions l'envoyer au tribunal compétent en AR<br>
                        Veuillez cliquer sur le lien ci-dessous afin de signer votre plainte.
                    </div>
                </div>
                <div class="row py-0  justify-content-center" v-if="(parseInt(data.state) === 2) ">
                    <div class="col-6 my-2" style="font-weight:bold">

                        <button @click="createSignature($event)" class="font-weight-bold rounded-50 btn btn-block btn-primary text-white">

                            <span v-if="!loadingSignature">Signer ma plainte</span>
                            <div v-if="loadingSignature" class="spinner-border text-white spinner-custom"
                                role="status">
                                <span class="sr-only">Chargement ...</span>
                            </div>
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="(data.tracking_number)">
            <div class="col-4" style="font-weight:bold">
                Suivie du courrier
            </div>
            <div class="col-8">
                <div class="row py-0  justify-content-center" >
                    <div class="col-6 my-0 py-0" style="font-weight:bold">
                        <a :href="'https://www.laposte.fr/outils/suivre-vos-envois?code='+data.tracking_number" target="_blank" class="font-weight-bold rounded-50 btn btn-block btn-primary text-white">
                            Suivre
                        </a>

                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
import Coordonnees from '../../../../models/coordonnees';
import CustomFormData from '../../../../models/customFormData';
import Infraction from '../../../../models/infraction';
import Prejudice from '../../../../models/prejudice';
import Qualification from '../../../../models/qualification';
import Tribunal from '../../../../models/tribunal';
import FormAction from './FormAction.vue';


export default {
    model:{
        prop:'data'
    },
    props: [
        "progress",
        "data",
        "tribunal"
    ],
    components: {
        FormAction
    },
    data() {
        return {
            loadingSignature:false
        }
    },
    watch: {
        // data(value) {
        //     // console.log(value)
        //     if (value && value.id) {
        //         this.form = data
        //     }
        // }
        // title (value) {
        //     this.$forceUpdate()
        // }
    },
    created() {

    },
    methods: {
        createSignature(event) {
            this.loadingSignature = true
            event.preventDefault()
            axios.post('/api/access/signature/create')///' + this.formId
                .then(response => {
                    window.gtrack({ event: 'myform_signature', event_category: 'plainteenligne', event_label: 'signature_ready', value: 'Plainte en ligne : signature prête' })
                    // this.url = response.data.url
                    // this.loading = false
                    // // console.log(response.data.url)
                    this.redirectToSignature(response.data.url)
                }).catch(error => {
                    // console.log(error)
                })
        },
        redirectToSignature(url) {
            // evt.preventDefault();
            window.location.replace(url);
            //  return false;
            // window.location.href = url
        },
        getFormState(){
            let complete =
            this.data.tribunal &&
            this.data.infraction &&
            this.data.qualification &&
            this.data.prejudice &&
            this.data.coordonnees &&
            Tribunal.isStaticComplete(this.data.tribunal)
            &&
            Infraction.isStaticComplete(this.data.infraction)
            &&
            Qualification.isStaticComplete(this.data.qualification)
            &&
            Prejudice.isStaticComplete(this.data.prejudice)
            &&
            Coordonnees.isStaticComplete(this.data.coordonnees)

            let etat = '';
            switch(parseInt(this.data.state)){
                case -1:
                case 0:
                    etat = 'Projet non validé'
                    break;
                case 1:
                    etat = 'Projet validé'
                    break;
                case 2:
                    etat = 'Payée mais non signée éléctroniquement'
                    break;
                case 3:
                    etat = 'En cours d\'envoi au service responsable des envois en AR'
                    break;
                case 4:
                    etat = 'En cours d\'envoi au tribunal compétent'
                    break;
                case 5:
                    etat = 'Plainte déposée au tribunal compétent'
                    break;
            }
            return {
                complete,
                etat
            }
        },


    }
}
</script>
