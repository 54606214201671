<template>

        <v-form v-if="!loading"
          ref="fourthStepForm"
          v-model="valid"
          @input="customValidation"

        >
          <div class="row" v-if="tribunalData">
            <div class="col-12">
              <v-autocomplete
                v-model="tribunalData.code"
                :rules="tribunalData.rules.code"
                :items="tribunauxData"
                item-text="name"
                item-value="code"
                :label="translate('form-step-four-label1')"
                hide-details="auto"
                :persistent-hint="true"
                outlined
                rounded
                no-data-text="Aucun tribunal trouvé !"
                class="secondary-input-bg"
              />
            </div>
            <div
              v-if="showErrors"
              class="col-12 text-danger text-center"
            >
              {{ 'error-general-validations' | translate }}
            </div>
            <div class="col-12">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6">
                    <button
                      class="font-weight-bold rounded-50 btn btn-block btn-secondary text-uppercase"
                      @click="showPrevious($event)"
                    >
                      Précédent
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button
                      class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase"
                      @click="validate($event)"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-form>

</template>
<script>
import CustomFormData from '../../../models/customFormData'
import Tribunal from '../../../models/tribunal'
export default {
  components: {
  },
  model: {
    prop: 'tribunal',
    event: 'validated'
  },
  props: [
    'tribunal',
    'tribunauxItems'
  ],
  data () {
    return {
      loading:true,
      previousRoute:'personnalData',
      nextRoute:'visualisation',
      valid: true,
      showErrors: false,
      tribunauxData:[],
      tribunalData:null
    }
  },
  watch:{
    '$route': 'fetchData',
    tribunal(value){
      this.fetchData()
    },
    tribunauxItems(value){
      this.fetchData()
    }
  //   tribunauxItems(value){
  //     if(value.length > 0){
  //       this.tribunauxData = Object.assign([], value)
  //       // // console.log(this.tribunaux)
  //     }
  //   },
  //   tribunal(value){
  //     if(value){
  //       this.tribunalData = Object.assign({}, value)
  //       // // console.log(this.tribunaux)
  //     }
  //   }
  },
  mounted () {
    this.setLogoLinkOnClick(this.showPrevious)
    // setTimeout(() => {
    //   this.tribunauxData = Object.assign([], this.tribunauxItems)
    //   this.tribunalData = Object.assign({}, this.tribunal)
    // }, 500)
    this.$emit('updateProgress', {
            timing: '',
            percentage: 0,
            actualStep: 4
        },)
        this.fetchData()
  },
  created () {



  },
  methods: {
    showPrevious (event) {
      event.preventDefault()
      this.$emit('previous',{
            'previousRoute':this.$route.name.indexOf('my-') > -1   ? 'my-display' : this.previousRoute
        })
    },
    validate (event) {
      event.preventDefault()
      this.$refs.fourthStepForm.validate()
      if (this.valid) {
        // window.gtrack('event', 'Submit', {'event_category': "Plainte", 'event_label': "Etape-1", 'value': 'Plainte'});
        window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'step4', value: 'Plainte en ligne : étape 4' })
        // console.log(this.tribunalData, this.tribunalData.code)
        let param = Object.assign({}, this.tribunalData)
        let newTribunalData = CustomFormData.searchTribunal(param.code, this.tribunauxData)
        // // console.log(this.tribunalData, this.tribunalData.code)
        this.$emit('saveDossier', {
            'key': 'tribunal',
            'data': newTribunalData,
            'nextRoute':this.$route.name.indexOf('my-') > -1  ? 'my-display' : this.nextRoute
        })
      } else {
        this.showErrors = true
      }
    },
    customValidation () {
      if (this.showErrors) {
        this.showErrors = !this.$refs.fourthStepForm.validate()
      }
    },
    fetchData(){
      this.getTribunal()
      this.getTribunaux()
      if(this.tribunalData  && this.tribunauxData.length){
        this.loading = false
      }
    },
    getTribunaux () {

      this.tribunauxData = this.tribunauxItems
    },
    getTribunal () {
        if(this.tribunal && this.tribunal.code){
             this.tribunalData = new Tribunal(this.tribunal.code, this.tribunal.name)
        }
        else{
            this.tribunalData = new Tribunal()
        }
      // this.tribunaux = this.tribunauxItems
      // axios.get('/api/tribunaux/get-all')
      //   .then(response => {
      //     this.tribunaux = response.data.tribunaux
      //   }).catch(error => {
      //     // console.log(error.response)
      //   })

    }
  }
}
</script>
<style scoped>
    h2.baseline {
        color: #68b8b7;
    }
    .list-inline{display:block;}
    .list-inline li{display:inline-block;margin-right: 0;}
    .list-inline li:after{content:'|'; margin:0 10px;}
    .list-inline li:last-child:after{content:'';}
</style>
