<template>
    <div v-if="(progress) && !this.$route.params.fromDisplay">
    <v-stepper
      v-if="(progress.actualStep > 0)"
      class="card-container mx-auto d-sm-none mb-n5 py-0"
      :class="{'mt-5' : progress.actualStep != 1}"
    >
      <v-stepper-header>
        <v-stepper-step
          :key="`${progress.actualStep}-step`"
          class="mx-auto py-0 v-stepper__step--active"
          :step="(progress.actualStep + '/' + Object.keys(steps).length)"
        >
          <span class="ml-1">{{ translate(steps[progress.actualStep].title) }}</span>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-stepper
      v-if="(progress.actualStep > 0)"
      v-model="progress.actualStep"
      class="card-container mx-auto d-sm-block d-none"
    >
      <v-stepper-header>
        <template v-for="n in steps" >
          <v-stepper-step v-if="(n.step > 0)"
            :key="n.step + `-step`"
            :complete="(progress.actualStep > (n.step))"

            :step="(n.step)"
          >
            <span class="ml-1">{{ progress.actualStep == (n.step) ? (translate(steps[progress.actualStep].title)) : '' }}</span>
          </v-stepper-step>
          <v-divider v-if="(n.step > 0) && (n.step !== Object.keys(steps).length )" :key="n.step" />
        </template>
      </v-stepper-header>
    </v-stepper>
</div>
</template>
<script>

export default {
    props: [
        'progress',
        'steps'
    ],
    components: {

    },
    data() {
        return {
          stepsData:[],
          progressData:{
            timing: '',
            percentage: 0,
            actualStep: 0
          }
        }
    },
    watch: {
      // steps(value){
      //   if(value){
      //     this.stepsData = JSON.parse(JSON.stringify(this.steps))
      //     // console.log(this.stepsData)
      //   }
      // },
      // progress(value){
      //   if(value){
      //     this.progressData = JSON.parse(JSON.stringify(this.progress))
      //       // console.log(this.progressData.actualStep)
      //   }
      // }

    },
    created() {

    },
    methods: {

    },
    mounted(){
      this.stepsData = this.steps;//JSON.parse(JSON.stringify(this.steps))
      this.progressData = this.progress; //JSON.parse(JSON.stringify(this.progress))
      // console.log(this.stepsData)
      // console.log(this.progressData.actualStep, this.progress)
    }
}
</script>
