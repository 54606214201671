<template>
    <div class="card mx-auto my-8 px-0 px-sm-3 card-container">
        <div class="card-body">
            <v-form @submit="checkForm" action="https://vuejs.org/" method="post">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>{{ errorsTitle }}</b>
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                            </p>
                            <div class="form-group">
                                <label for="form_id">N° de dossier</label>
                                <input type="text" class="form-control" id="form_id" v-model="form_id" name="form_id"
                                    placeholder="">

                            </div>
                            <div class="form-group">
                                <label for="form_reference">Référence du dossier</label>
                                <input type="text" class="form-control" id="form_reference" v-model="form_reference"
                                    name="form_reference" placeholder="">
                            </div>
                        
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <button type="submit"
                            class="font-weight-bold rounded-50 btn btn-block btn-primary text-uppercase">Ouvir mon
                            dossier</button>

                    </div>
                </div>
                
            </div>
        </v-form>
        </div>
    </div>

</template>
<script>

export default {
    props: [
        "progress",

    ],
    components: {

    },
    data() {
        return {
            errorsTitle: null,
            errors: [],
            form_id: null,
            form_reference: null
        }
    },
    watch: {

    },
    created() {

    },
    methods: {
        loginDossier(id, ref) {
            //localStorage.setItem('sessionId', form.id)
            let urlAccessToken = '/api/access-token/' + id + '/' + ref

            if (urlAccessToken) {
                axios.get(urlAccessToken)
                    .then(response => {
                        localStorage.setItem('__data_access_token', response.data['access-token']);
                        // localStorage.setItem('__data_id', id)
                        // localStorage.setItem('__data_ref', ref)

                        this.$emit('loggedDossier')

                        // window.gtrack({ event: 'form_step', event_category: 'plainteenligne', event_label: 'signature_ready', value: 'Plainte en ligne : signature prête' })
                        // // this.url = response.data.url
                        // // this.loading = false
                        // this.redirectToSignature(response.data.url)

                    }).catch(error => {
                        console.log(error)
                        this.errors.push('N° du dossier et/ou référence du dossier invalide(s) .');
                        this.errorsTitle = "Identification incorrecte"
                    })
            }
        },
        checkForm: function (e) {
            e.preventDefault();

            this.errors = [];

            if (!this.form_id) {
                this.errors.push('N° de dossier.');
            }
            if (!this.form_reference) {
                this.errors.push('Référence du dossier.');
            }
            if (this.errors.length > 0) {
                this.errorsTitle = "Informations manquantes :"
            } else {
                this.loginDossier(this.form_id, this.form_reference)

            }
        }
    }
}
</script>