export default class Coordonnees {
  constructor (
    civilite = '',
    firstName = '',
    lastName = '',
    company = '',
    fullAddress = '',
    address = '',
    code = '',
    city = '',
    phone = '',
    email = '',
    majeur = false,
    juridique = false
  ) {
    this.civilite = civilite

    this.firstName = firstName

    this.lastName = lastName

    this.company = company

    this.fullAddress = fullAddress

    this.address = address

    this.code = code

    this.city = city

    this.phone = phone

    this.email = email

    this.majeur = majeur

    this.juridique = juridique

    this.rules = {
      civilite: [
        value =>
          value === 'monsieur' ||
                    value === 'madame' ||
                    'Veuillez choisir votre civilité'
      ],
      firstName: [value => !!value || 'Ce champ est obligatoire'],
      lastName: [value => !!value || 'Ce champ est obligatoire'],
      company: [],
      address: [value => !!value || 'Ce champ est obligatoire'],
      code: [
        value => !!value || 'Ce champ est obligatoire',
        value =>
          /^[0-9]{4,5}$/.test(value) ||
                    "Le code postal n'est pas valide"
      ],
      city: [value => !!value || 'Ce champ est obligatoire'],
      phone: [
        value =>
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(
            value
          ) ||
                    value === '' ||
                    'Le numéro de téléphone doit être valide'
      ],
      email: [
        value => !!value || 'Ce champ est obligatoire',
        value =>
        // /.+@.+\..+/.test(value) ||
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                    "L'adresse email doit être valide"
      ],
      majeur: [
        value => value || 'Veuillez confirmer que vous êtes majeur(e)'
      ],
      juridique: [value => value || 'Veuillez cocher la case obligatoire']
    }
  }
  isComplete(){
    return (this.civilite &&
      this.firstName &&
      this.lastName &&
      // this.fullAddress &&
      this.address &&
      this.code &&
      this.city &&
      this.phone &&
      this.email )
  }
  static isStaticComplete(item){
    return (item.civilite &&
      item.firstName &&
      item.lastName &&
      // item.fullAddress &&
      item.address &&
      item.code &&
      item.city &&
      item.phone &&
      item.email )
  }
}
