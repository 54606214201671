export default class InfractionParent {
  constructor (id = '', label = '', children=[], special=false) {
    this.id = id

    this.label = label
    this.children = children
    this.special = special
    
    this.rules = {

    }
  }
}
