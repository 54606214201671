<template>

    <div class="container" v-if=" (data.id)">
        <v-scroll-x-transition mode="out-in" appear>

        <div class="card mx-auto my-8 px-0 px-sm-3 card-container">
            <div class="card-body">
        <router-view :data="data" :formId="data.id" :formReference="data.reference"
            :infraction="data.infraction" :qualification="data.qualification"
            :prejudice="data.prejudice" :coordonnees="data.coordonnees" :tribunal="data.tribunal"
            :infractionsItems="infractionsItems" :tribunauxItems="tribunauxItems"
            @saveDossier="saveForm($event)" @previous="previousEvent($event)"

        >
        </router-view>
    </div>
        </div>
    </v-scroll-x-transition>
        <!-- <Display :data="data" :tribunal="tribunal"></Display> -->
    </div>
    <div class="container" v-else-if=" (!data.id)">
        <Login @loggedDossier="loginLoadDossier()"></Login>
    </div>

</template>
<script>
import Login from './MyForm/Login.vue'
import Display from './MyForm/Display.vue'
export default {
    props: [
        "data",
        "infraction",
        // "tribunaux",
        "tribunauxItems",
        "infractionsItems"
    ],
    components: {
        Login,
        Display
    },
    data() {
        return {
            form: null,
            loaded:false,
            tribunal:null
        }
    },
    watch: {
        data (value){
            if(value){
                this.loaded = true
                this.getTribunal()
            }
        }
    },
    created() {

    },
    mounted(){
        // if(localStorage.getItem('__data_access_token')){
        //     this.loadDossier(()=>{
        //         this.loaded = true;
        //         this.getTribunal()
        //     });
        // }
        // else{
        //     this.loaded = true;
        // }
        },
    methods: {
        loginLoadDossier(){
            this.$emit('loginLoadDossier')
        },
        getTribunal(){

                        for (let index = 0; index < this.tribunaux.length; index++) {
                            const element = this.tribunaux[index];
                            if(element.code === this.data.tribunal_code){
                                this.tribunal = element.name
                            }

                        }



        },

        saveForm(event) {
            this.$emit('saveForm', event)



        },
        previousEvent(event) {
            // console.log(event.previousRoute)
            this.$router.push({ name: event.previousRoute })
        }

    }
}
</script>
