
import * as env from './env';
window.gtrack = function(){

};
window.btrack = function(){

}
window.removeGA = function () {
    window.gtrack = function(){

    };
    window.btrack = function(){

    }
}
window.launchGA = function () {
    // console.log("Tracking actif");
    (function (w, d, t, r, u) { var f, n, i; w[u] = w[u] || [], f = function () { var o = { ti: "56371176" }; o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad") }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () { var s = this.readyState; s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null) }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i) })(window, document, "script", "//bat.bing.com/bat.js", "uetq");

    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-' + env.GTM);


    window.gtrack = function(trackingData){
        window.dataLayer = window.dataLayer || [];
            if (typeof window.dataLayer !== 'undefined' && typeof window.dataLayer.push === 'function') {
                window.dataLayer.push(trackingData);
                // console.log("Tracking : " + trackingData.event + " / " + trackingData.event_label + (trackingData.value ? (" (" + trackingData.value + ")") : ""));
            } else {
                // console.log("Problème de tracking");
            }

    };
    window.btrack = function(event, action, options){
            if (typeof window.uetq !== 'undefined' && typeof window.uetq.push === 'function') {
                window.uetq = window.uetq || [];
                window.uetq.push(event, action, options);
                // console.log("Tracking actif : " + action);
            } else {
                // console.log("Problème de tracking");
            }

    }
}
class Axeptio {

    init() {
        window.axeptioSettings = {
            clientId: env.AXEPTIO,
        };
        let scripts = document.getElementsByTagName('scripts')
        let bContinue = true
        for (let index = 0; index < scripts.length; index++) {
            const element = scripts[index];
            if(element.src === '//static.axept.io/sdk.js'){
                bContinue = false;
                break;
            }
        }
        if(bContinue){
        (function (d, s) {

            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
        })(document, "script");


        void 0 === window._axcb && (window._axcb = []);
        window._axcb.push(function (axeptio) {
            axeptio.on("cookies:complete",  (choices) => {
                if (choices.google_analytics) {
                    launchGA();
                }
                else {
                    // console.log("Tracking non actif");
                    removeGA();
                }
            });

        });
    }
    }

}

export default Axeptio;
